import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'

import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

const PdfViewer = ({ url }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = ({ numPages: numberOfPages }) => {
    setNumPages(numberOfPages)
  }

  useEffect(() => {
    setPageNumber(1)
  }, [url])

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      className="relative flex justify-center pb-4 mb-12 overflow-hidden sm:min-h-[858px]"
      loading={false}
    >
      <Page pageNumber={pageNumber} loading={false} />
      {/* Navigation buttons */}
      <div className="absolute flex items-center bg-white rounded shadow bottom-1 md:bottom-0">
        <button
          type="button"
          className="p-2 text-gray-800 rounded-l hover:text-white hover:bg-primary-400 disabled:bg-gray-200 disabled:text-gray-800 disabled:cursor-not-allowed"
          onClick={() => setPageNumber((prev) => prev - 1)}
          disabled={pageNumber === 1}
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </button>
        <span className="w-[96px] text-center">
          {pageNumber} of {numPages}
        </span>
        <button
          type="button"
          className="p-2 text-gray-800 rounded-r hover:text-white hover:bg-primary-400 disabled:bg-gray-200 disabled:text-gray-800 disabled:cursor-not-allowed"
          onClick={() => setPageNumber((prev) => prev + 1)}
          disabled={pageNumber === numPages}
        >
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
    </Document>
  )
}

PdfViewer.propTypes = {
  url: PropTypes.string.isRequired,
}

export default PdfViewer
