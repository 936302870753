import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import PublicRoutes from './public'
import AppRoutes from './app'
import ErrorPage from './error.page'

const Routes = () => (
  <Switch>
    <Route path="/error" exact component={ErrorPage} />
    <Route path="/app" component={AppRoutes} />
    <Route path="/" component={PublicRoutes} />
    <Redirect to="/app" />
  </Switch>
)

export default Routes
