import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import useRegister from 'hooks/auth/useRegister'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import MainLayout from 'common/layouts/main.layout'
import Input from 'common/components/inputs/input'
import Checkbox from 'common/components/inputs/checkbox'
import Button from 'common/components/buttons/button'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'

const RegisterForm = () => {
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useRegister()

  const [captchaToken, setCaptchaToken] = useState()

  const onSubmit = (data) => mutate({ data, captchaToken })

  return (
    <div className="flex flex-col space-y-6 px-10 py-12 bg-white rounded-lg w-[448px] shadow-lg">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <h2 className="text-lg font-semibold text-center">Kayıt Ol</h2>
          <Input
            label="E-posta"
            type="email"
            name="email"
            ref={methods.register({
              required: 'Lütfen e-posta alanını doldurunuz!',
            })}
            error={methods.errors.email?.message}
          />
          <Input
            label="Şifre"
            name="password"
            type="password"
            ref={methods.register({
              required: 'Lütfen şifre alanını doldurunuz!',
            })}
            error={methods.errors.password?.message}
          />
          <Input
            label="Şifre (tekrar)"
            name="passwordAgain"
            type="password"
            ref={methods.register({
              required: 'Lütfen şifre (tekrar) alanını doldurunuz!',
              validate: (value) =>
                value === methods.watch('password') ||
                'Tekrar girilen şifre doğru değil!',
            })}
            error={methods.errors.passwordAgain?.message}
          />
          <Input
            label="İsim"
            name="firstName"
            type="text"
            ref={methods.register({
              required: 'Lütfen isim alanını doldurunuz!',
            })}
            error={methods.errors.firstName?.message}
          />
          <Input
            label="Soyisim"
            name="lastName"
            type="text"
            ref={methods.register({
              required: 'Lütfen soyisim alanını doldurunuz!',
            })}
            error={methods.errors.lastName?.message}
          />
          <Checkbox
            name="userAgreementAndPrivacyPolicy"
            label={
              <span className="flex flex-wrap text-sm gap-x-2">
                <Button
                  href="/legal/user-agreement"
                  variant="default"
                  color="primary"
                >
                  Kullanım Koşullarını,
                </Button>
                <Button
                  href="/legal/privacy-policy"
                  variant="default"
                  color="primary"
                >
                  Gizlilik Politikasını,
                </Button>
                <Button
                  href="/legal/privacy-policy"
                  variant="default"
                  color="primary"
                >
                  Çerez Politikasını
                </Button>
                ve
                <Button
                  href="/legal/privacy-policy"
                  variant="default"
                  color="primary"
                >
                  Aydınlatma Metnini
                </Button>
                kabul ediyorum.
              </span>
            }
            ref={methods.register({
              required: 'Lutfen sozlesmeleri okuyunuz ve kabul ediniz!',
            })}
            error={methods.errors.userAgreementAndPrivacyPolicy?.message}
          />
          <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            status={isLoading ? 'disabled' : ''}
          >
            {isLoading ? <Spinner color="white" /> : 'Kayıt Ol'}
          </SubmitButton>
          {error && (
            <Alert type="error" variant="contained" message={error.message} />
          )}
          {isSuccess && (
            <>
              <Alert
                type="success"
                variant="contained"
                message="Başarıyla kayıt olundu! Size email adresinizi doğrulamanız için bir email gönderdik, emaildeki linke tıklayarak email doğrulama işlemini gerçekleştirebilirsiniz.."
              />
            </>
          )}
        </form>
      </FormProvider>
    </div>
  )
}

const RegisterPage = () => (
  <MainLayout>
    <div className="flex justify-center px-4 py-8 md:py-20">
      <RegisterForm />
    </div>
  </MainLayout>
)

export default RegisterPage
