import { useMutation } from 'react-query'
import { createProfessorComment } from 'api/professorComment.api'

export default (professorId) =>
  useMutation(
    ({ data, captchaToken }) =>
      createProfessorComment(professorId, data, captchaToken),
    {
      mutationKey: ['create-professor-comment'],
    }
  )
