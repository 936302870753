import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Alert from 'common/components/alert'

const Dropdown = React.forwardRef(
  (
    {
      label,
      options,
      id,
      name,
      defaultValue,
      value,
      onChange,
      shadowInner,
      className,
      error,
    },
    ref
  ) => (
    <div className={classNames('flex flex-col', className)}>
      {label && (
        <label htmlFor={id} className="mb-2 font-semibold">
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        className={classNames(
          'border rounded-md border-light focus:border-primary-500',
          {
            'bg-secondary-50 shadow-inner border-none': shadowInner,
          }
        )}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        ref={ref}
      >
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
      {error && <Alert type="error" message={error} />}
    </div>
  )
)

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape),
  name: PropTypes.string,
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  shadowInner: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
}

Dropdown.defaultProps = {
  label: '',
  options: [],
  name: null,
  id: null,
  defaultValue: undefined,
  value: undefined,
  onChange: null,
  shadowInner: false,
  className: '',
  error: '',
}

export default Dropdown
