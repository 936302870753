import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

import Alert from '../alert'
import Tooltip from '../tooltip'

const Input = React.forwardRef(
  (
    {
      label,
      subLabel,
      type,
      id,
      name,
      placeholder,
      border,
      required,
      startAdornment,
      endAdornment,
      className,
      labelClassName,
      wrapperClassName,
      tooltip,
      error,
      ...props
    },
    ref
  ) => (
    <div className={clsx('flex flex-col flex-1 space-y-2', wrapperClassName)}>
      {label && (
        <label
          htmlFor={id}
          className={clsx(
            'font-bold flex items-center space-x-1',
            labelClassName
          )}
        >
          <span>{label}</span>
          {required && <span className="text-danger-500">*</span>}
          {subLabel && (
            <span className="text-sm font-normal text-gray-500">
              {subLabel}
            </span>
          )}
          {tooltip && (
            <span>
              <QuestionMarkCircleIcon
                className="w-5 h-5 text-gray-500 cursor-help"
                data-for={tooltip.id}
                data-tip
              />
              <Tooltip id={tooltip.id}>{tooltip.content}</Tooltip>
            </span>
          )}
        </label>
      )}
      <div className="relative">
        {(startAdornment || endAdornment) && (
          <span
            className={clsx(
              'absolute inset-y-0 flex items-center',
              startAdornment && 'left-0 pl-3',
              endAdornment && 'right-0 pr-3'
            )}
          >
            {startAdornment || endAdornment}
          </span>
        )}
        <input
          id={id}
          type={type}
          name={name}
          className={clsx(
            'w-full py-3 rounded ring-0 outline-none',
            border
              ? 'border border-light focus:border-primary-500'
              : 'border-none',
            startAdornment ? 'pl-10' : 'pl-2',
            endAdornment ? 'pr-10' : 'pr-2',
            className
          )}
          placeholder={placeholder}
          onWheel={(e) => e.target.blur()} // Disable input scroll
          {...props}
          ref={ref}
        />
      </div>
      {error && <Alert type="error" message={error} />}
    </div>
  )
)

Input.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  required: PropTypes.bool,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  tooltip: PropTypes.shape(),
  error: PropTypes.string,
}

Input.defaultProps = {
  label: '',
  subLabel: '',
  type: 'text',
  id: null,
  placeholder: '',
  border: true,
  required: false,
  startAdornment: null,
  endAdornment: null,
  className: '',
  labelClassName: '',
  wrapperClassName: '',
  tooltip: null,
  error: '',
}

export default Input
