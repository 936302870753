import React from 'react'
import clsx from 'clsx'
import { useLocation } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/outline'

import MainLayout from 'common/layouts/main.layout'
// import Ads from 'common/components/ads'
import Button from 'common/components/buttons/button'
import PdfViewer from 'common/components/pdfViewer'

export const AGREEMENT_LINKS = [
  {
    href: '/legal/user-agreement',
    text: 'Kullanım Koşulları',
    file: '/pdf/kullanim-kosullari.pdf',
  },
  {
    href: '/legal/privacy-policy',
    text: 'Gizlilik Politikası',
    file: '/pdf/gizlilik-politikasi.pdf',
  },
  {
    href: '/legal/cookie-policy',
    text: 'Çerez Politikası',
    file: '/pdf/cerez-politikasi.pdf',
  },
  {
    href: '/legal/clarification-text',
    text: 'Aydınlatma Metni',
    file: '/pdf/aydinlatma-metni.pdf',
  },
]

const AgreementsView = () => {
  const location = useLocation()

  return (
    <>
      <nav className="lg:w-[300px] lg:absolute pt-8 lg:pt-16">
        <ul className="flex flex-col items-center space-y-2 lg:items-start">
          {AGREEMENT_LINKS.map(({ text, href }, index) => {
            const isActive = location.pathname.includes(href)

            return (
              <li key={index} className="flex space-x-2">
                <ChevronRightIcon
                  className={clsx(
                    'w-6 h-6 p-0.5 rounded-full',
                    isActive
                      ? 'bg-primary-50 text-primary-400'
                      : 'bg-gray-50 text-gray-500'
                  )}
                />
                <Button
                  href={href}
                  color="custom"
                  className={clsx(
                    isActive ? 'text-primary-400' : 'text-gray-500'
                  )}
                >
                  {text}
                </Button>
              </li>
            )
          })}
        </ul>
      </nav>
      <section className="lg:pl-[316px]">
        <PdfViewer
          url={
            AGREEMENT_LINKS.find((item) =>
              location.pathname.includes(item.href)
            ).file
          }
        />
      </section>
    </>
  )
}

const LegalPage = () => (
  <MainLayout>
    <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
      <AgreementsView />
      {/* <Ads /> */}
    </div>
  </MainLayout>
)

export default LegalPage
