import React from 'react'
import PropTypes from 'prop-types'

const InfoField = ({ title, children }) => (
  <div className="flex-1 space-y-3">
    {title && <h3 className="font-bold border-b border-light">{title}</h3>}
    <table className="w-full border-collapse">
      <tbody>{children}</tbody>
    </table>
  </div>
)

InfoField.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}

InfoField.defaultProps = {
  title: '',
}

export default InfoField
