import React from 'react'

import MainLayout from 'common/layouts/main.layout'
// import Ads from 'common/components/ads'
import ProfessorSearchBox from 'common/views/app/professorSearchBox.view'
import SchoolSearchBox from 'common/views/app/schoolSearchBox.view'

const HomePage = () => (
  <MainLayout>
    <div
      className="bg-center bg-cover"
      style={{ backgroundImage: "url('/images/home-hero.jpeg')" }}
    >
      <div className="bg-black/[0.5] text-center p-16">
        <h1 className="text-4xl font-extrabold text-white">ProfSözlük</h1>
        <h2 className="text-2xl font-extrabold text-white">
          Artık Not Verme Sırası Sende
        </h2>
        <div className="flex flex-col py-16 space-y-4 md:space-y-0 md:space-x-12 md:flex-row justify-evenly">
          <ProfessorSearchBox />
          <SchoolSearchBox />
        </div>
      </div>
    </div>
    {/* <div className="container px-4 mx-auto my-16 lg:px-0">
      <Ads />
    </div> */}
    <div className="container px-4 mx-auto my-16 lg:px-0">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col justify-center flex-1 space-y-8">
          <img className="h-[200px]" src="/images/home-feature-1.svg" alt="" />
          <h1 className="text-2xl font-bold text-center">
            Yorum bırakarak arkadaşlarına yardım et
          </h1>
        </div>
        <div className="flex flex-col justify-center flex-1 space-y-8">
          <img
            className="h-[200px] items-end"
            src="/images/home-feature-2.svg"
            alt=""
          />
          <h1 className="text-2xl font-bold text-center">
            Yorumlarının her zaman anonim olduğunu unutma
          </h1>
        </div>
        <div className="flex flex-col justify-center flex-1 space-y-8">
          <img className="h-[200px]" src="/images/home-feature-3.svg" alt="" />
          <h1 className="text-2xl font-bold text-center">
            Başkalarının yorumlarını oyla
          </h1>
        </div>
      </div>
    </div>
    {/* <div className="container px-4 mx-auto my-16 lg:px-0">
      <Ads />
    </div> */}
  </MainLayout>
)

export default HomePage
