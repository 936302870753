import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import useUserMe from 'hooks/user/useUserMe'
import useUpdateUserMe from 'hooks/user/useUpdateUserMe'
import useResetPassword from 'hooks/auth/useResetPassword'

import MainLayout from 'common/layouts/main.layout'
// import Ads from 'common/components/ads'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'
import getToken from 'utils/getToken'

const ProfileInfoForm = () => {
  const methods = useForm()
  const token = getToken()
  const {
    data: user,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
    error: userError,
  } = useUserMe({ enabled: !!token })
  const {
    mutate: mutateUpdateUser,
    isLoading: isUserUpdateLoading,
    isSuccess: isUserUpdateSuccess,
    error: userUpdateError,
  } = useUpdateUserMe()

  useEffect(() => {
    if (isUserSuccess) {
      methods.reset(user)
    }
  }, [isUserSuccess])

  const onSubmit = (data) => mutateUpdateUser(data)

  return isUserLoading ? (
    <Spinner />
  ) : userError ? (
    <Alert type="error" variant="contained" message={userError.message} />
  ) : (
    <div className="p-6 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <h1 className="text-xl font-bold">Profil Bilgileri</h1>
          <Input
            label="Ad"
            type="text"
            name="firstName"
            ref={methods.register({
              required: 'Lütfen ad alanını doldurunuz!',
            })}
            error={methods.errors.firstName?.message}
          />
          <Input
            label="Soyad"
            type="text"
            name="lastName"
            ref={methods.register({
              required: 'Lütfen soyad alanını doldurunuz!',
            })}
            error={methods.errors.lastName?.message}
          />
          <SubmitButton
            variant="contained"
            color="secondary"
            size="large"
            status={isUserUpdateLoading ? 'disabled' : ''}
            className="self-start"
          >
            {isUserUpdateLoading ? <Spinner color="white" /> : 'Kaydet'}
          </SubmitButton>
          {userUpdateError && (
            <Alert
              type="error"
              variant="contained"
              message={userUpdateError.message}
            />
          )}
          {isUserUpdateSuccess && (
            <Alert
              type="success"
              variant="contained"
              message="Profil güncellendi!"
            />
          )}
        </form>
      </FormProvider>
    </div>
  )
}

const ResetPasswordForm = () => {
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useResetPassword()

  const onSubmit = (data) => mutate(data)

  return (
    <div className="p-6 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <h1 className="text-xl font-bold">Şifre Sıfırla</h1>
          <Input
            label="Eski Şifre"
            type="password"
            name="oldPassword"
            ref={methods.register({
              required: 'Lütfen eski şifre alanını doldurunuz!',
            })}
            error={methods.errors.oldPassword?.message}
          />
          <Input
            label="Yeni Şifre"
            type="password"
            name="newPassword"
            ref={methods.register({
              required: 'Lütfen yeni şifre alanını doldurunuz!',
            })}
            error={methods.errors.newPassword?.message}
          />
          <Input
            label="Yeni Şifre (tekrar)"
            type="password"
            name="newPasswordAgain"
            ref={methods.register({
              required: 'Lütfen yeni şifre (tekrar) alanını doldurunuz!',
              validate: (value) =>
                value === methods.watch('newPassword') ||
                'Girilen yeni şifreler uyuşmuyor!',
            })}
            error={methods.errors.newPasswordAgain?.message}
          />
          <SubmitButton
            variant="contained"
            color="secondary"
            size="large"
            status={isLoading ? 'disabled' : ''}
            className="self-start"
          >
            {isLoading ? <Spinner color="white" /> : 'Sıfırla'}
          </SubmitButton>
          {error && (
            <Alert type="error" variant="contained" message={error.message} />
          )}
          {isSuccess && (
            <Alert
              type="success"
              variant="contained"
              message="Şifre sıfırlandı!"
            />
          )}
        </form>
      </FormProvider>
    </div>
  )
}

const ProfilePage = () => (
  <MainLayout>
    <div className="container flex flex-col px-4 pt-8 pb-8 mx-auto space-y-8 md:pt-12 md:pb-20">
      <h1 className="text-3xl font-bold">Profil & Hesap Bilgileri</h1>
      <ProfileInfoForm />
      <ResetPasswordForm />
      {/* <Ads /> */}
    </div>
  </MainLayout>
)

export default ProfilePage
