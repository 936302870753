import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon } from '@heroicons/react/outline'

import Tooltip from '../tooltip'
import ConditionalWrapper from '../conditionalWrapper'

const STATUSES = {
  fetching: 'opacity-50 cursor-wait',
  disabled: 'opacity-50 cursor-not-allowed',
}

const SIZES = {
  large: 'px-8 py-3',
  medium: 'px-4 py-2',
  small: 'px-2 py-1',
}

const COLORS = {
  contained: {
    primary:
      'bg-primary-500 border border-primary-500 hover:border-primary-600 hover:bg-primary-600 text-white',
    secondary:
      'bg-secondary-500 border border-secondary-500 hover:border-secondary-600 hover:bg-secondary-600 text-white',
    danger:
      'bg-danger-500 border border-danger-500 hover:border-danger-600 hover:bg-danger-600 text-white',
    success:
      'bg-success-500 border border-success-500 hover:border-success-600 hover:bg-success-600 text-white',
    warning:
      'bg-warning-500 border border-warning-500 hover:border-warning-600 hover:bg-warning-600 text-white',
    gray: 'bg-gray-500 border border-gray-500 hover:border-gray-600 hover:bg-gray-600 text-white',
  },
  outlined: {
    primary:
      'border border-primary-500 focus:border-primary-500 hover:bg-primary-500 text-primary-500 hover:text-white',
    secondary:
      'border border-secondary-500 focus:border-secondary-500 hover:bg-secondary-500 text-secondary-500 hover:text-white',
    danger:
      'border border-danger-500 focus:border-danger-500 hover:bg-danger-500 text-danger-500 hover:text-white',
    success:
      'border border-success-500 focus:border-success-500 hover:bg-success-500 text-success-500 hover:text-white',
    warning:
      'border border-warning-500 focus:border-warning-500 hover:bg-warning-500 text-warning-500 hover:text-white',
    gray: 'border border-gray-500 focus:border-gray-500 hover:bg-gray-500 text-gray-500 hover:text-white',
    transparent:
      'border border-light focus:border-light hover:border-gray-500 text-primary-500',
  },
  default: {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    danger: 'text-danger-500',
    success: 'text-success-500',
    warning: 'text-warning-500',
    gray: 'text-secondary-900',
  },
}

const Button = ({
  children,
  href,
  isExternal,
  variant,
  color,
  size,
  startAdornment,
  status,
  onClick,
  className,
  tooltip,
}) => (
  <>
    {href ? (
      isExternal ? (
        <a
          href={href}
          className={clsx(
            'flex justify-center items-center rounded font-medium pr-2',
            COLORS[variant][color],
            STATUSES[status],
            variant !== 'default' && SIZES[size],
            variant === 'default' && 'hover:underline',
            className
          )}
          target="_blank"
          rel="noreferrer"
          data-for={tooltip?.id}
          data-tip
        >
          <div className="relative flex items-center">
            <span>{children}</span>
            <ExternalLinkIcon className="absolute w-3.5 h-3.5 -right-3 -top-1" />
          </div>
        </a>
      ) : (
        <Link
          to={href}
          onClick={onClick}
          className={clsx(
            'flex justify-center items-center rounded font-medium',
            COLORS[variant][color],
            STATUSES[status],
            variant !== 'default' && SIZES[size],
            variant === 'default' && 'hover:underline',
            className
          )}
          data-for={tooltip?.id}
          data-tip
        >
          <div className="flex items-center space-x-2">
            {startAdornment}
            <span>{children}</span>
          </div>
        </Link>
      )
    ) : (
      <ConditionalWrapper
        condition={tooltip?.id}
        wrapper={({ children: wrapperChildren }) => (
          <span className={clsx(className)} data-for={tooltip?.id} data-tip>
            {wrapperChildren}
          </span>
        )}
      >
        <button
          type="button"
          onClick={onClick}
          className={clsx(
            'flex justify-center items-center rounded font-medium',
            COLORS[variant][color],
            STATUSES[status],
            variant !== 'default' && SIZES[size],
            variant === 'default' && 'hover:underline',
            !tooltip?.id && className
          )}
          disabled={status === 'disabled'}
        >
          <div className="flex items-center space-x-2">
            {startAdornment}
            <div className="flex items-center space-x-2">{children}</div>
          </div>
        </button>
      </ConditionalWrapper>
    )}
    {tooltip && <Tooltip id={tooltip.id}>{tooltip.content}</Tooltip>}
  </>
)

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  href: PropTypes.string,
  isExternal: PropTypes.bool,
  variant: PropTypes.oneOf(['contained', 'outlined', 'default']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'success',
    'warning',
    'gray',
    'transparent',
    'custom',
  ]),
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  startAdornment: PropTypes.node,
  status: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltip: PropTypes.shape(),
}

Button.defaultProps = {
  href: '',
  isExternal: false,
  variant: 'default',
  color: 'primary',
  size: 'medium',
  startAdornment: null,
  status: '',
  onClick: () => {},
  className: '',
  tooltip: null,
}

export default Button
