import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { login } from 'api/auth.api'

export default () => {
  const history = useHistory()

  return useMutation((data) => login(data), {
    mutationKey: 'login',
    onSuccess: (data) => {
      window.localStorage.setItem('token', data.token)
      window.localStorage.setItem('userId', data.id)
      history.push('/')
    },
  })
}
