import { useMutation } from 'react-query'
import { createSchoolComment } from 'api/schoolComment.api'

export default (schoolId) =>
  useMutation(
    ({ data, captchaToken }) =>
      createSchoolComment(schoolId, data, captchaToken),
    {
      mutationKey: ['create-school-comment'],
    }
  )
