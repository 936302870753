import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const FooterSection = ({ children, title }) => (
  <div className="font-bold">
    <h4 className="mb-4 uppercase text-primary-200">{title}</h4>
    {children}
  </div>
)

FooterSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  title: PropTypes.string.isRequired,
}

const FooterSectionLinks = ({ children }) => (
  <div className="flex flex-col space-y-2">{children}</div>
)

FooterSectionLinks.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const FooterSectionLink = ({ children, href }) => (
  <Link to={href} className="hover:text-secondary-100">
    {children}
  </Link>
)

FooterSectionLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const FooterSectionExternalLink = ({ children, href }) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    className="hover:text-secondary-100"
  >
    {children}
  </a>
)

FooterSectionExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const Footer = () => (
  <footer className="pb-16 text-white bg-primary-400">
    <div className="container flex flex-col justify-between px-4 mx-auto space-y-16 md:flex-row md:space-y-0 py-14">
      <h3>
        <Link to="/" className="text-2xl font-extrabold">
          <span>Prof</span>
          <span className="text-secondary-900">Sözlük</span>
        </Link>
      </h3>
      <div className="flex flex-col space-y-8 md:flex-row md:space-x-20 md:space-y-0">
        <FooterSection title="HAKKIMIZDA">
          <FooterSectionLinks>
            <FooterSectionLink href="/about-us">Hakkımızda</FooterSectionLink>
            <FooterSectionExternalLink href="mailto:info@profsozluk.com">
              İletişim
            </FooterSectionExternalLink>
          </FooterSectionLinks>
        </FooterSection>
        <FooterSection title="YASAL">
          <FooterSectionLinks>
            <FooterSectionLink href="/legal/user-agreement">
              Kullanım Koşulları
            </FooterSectionLink>
            <FooterSectionLink href="/legal/privacy-policy">
              Gizlilik Politikası
            </FooterSectionLink>
            <FooterSectionLink href="/legal/cookie-policy">
              Çerez Politikası
            </FooterSectionLink>
            <FooterSectionLink href="/legal/clarification-text">
              Aydınlatma Metni
            </FooterSectionLink>
          </FooterSectionLinks>
        </FooterSection>
        <FooterSection title="SOSYAL">
          <FooterSectionLinks>
            <FooterSectionExternalLink href="https://twitter.com/ProfSozluk">
              Twitter
            </FooterSectionExternalLink>
            <FooterSectionExternalLink href="https://www.instagram.com/profsozluk/">
              Instagram
            </FooterSectionExternalLink>
          </FooterSectionLinks>
        </FooterSection>
      </div>
    </div>
    <div style={{ fontSize: 8, textAlign: 'center' }}>
      This site is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </footer>
)

export default Footer
