import React from 'react'
import PropTypes from 'prop-types'

import Header from 'common/components/header'
import Footer from 'common/components/footer'
import clsx from 'clsx'

const MainLayout = ({ children, className }) => (
  <div className={clsx('bg-bodyBackground', className)}>
    <Header />
    {children}
    <Footer />
  </div>
)

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

MainLayout.defaultProps = {
  className: '',
}

export default MainLayout
