import { useMutation } from 'react-query'
import { createProfessorCorrection } from 'api/professorCorrection.api'

export default (professorId) =>
  useMutation(
    ({ data, captchaToken }) =>
      createProfessorCorrection(professorId, data, captchaToken),
    {
      mutationKey: ['create-professor-correction'],
    }
  )
