import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import useSchool from 'hooks/school/useSchool'
import useCreateSchoolComment from 'hooks/schoolComment/useCreateSchoolComment'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import InfoField from 'common/components/infoField'
import InfoItem from 'common/components/infoItem'
import Slider from 'common/components/inputs/slider'
import Textarea from 'common/components/inputs/textarea'
import SubmitButton from 'common/components/buttons/submitButton'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'

const COMMENT_MAX_LENGTH = 1500

const RateSchoolForm = () => {
  const { id } = useParams()
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useCreateSchoolComment(id)

  const [captchaToken, setCaptchaToken] = useState()

  const onSubmit = (data) => mutate({ data, captchaToken })

  useEffect(() => {
    if (isSuccess) {
      methods.reset()
    }
  }, [isSuccess])

  return (
    <div className="p-4 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <InfoField>
            <InfoItem label="İtibar" labelClassName="w-64">
              <Controller
                control={methods.control}
                name="reputationScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen itibar alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Olanaklar">
              <Controller
                control={methods.control}
                name="opportunitiesScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen olanaklar alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Mutluluk">
              <Controller
                control={methods.control}
                name="happinessScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen mutluluk alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Öğrenci Toplulukları">
              <Controller
                control={methods.control}
                name="clubsScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen öğrenci toplulukları alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="İnternet">
              <Controller
                control={methods.control}
                name="internetScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen internet alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Sosyal Tesisler">
              <Controller
                control={methods.control}
                name="facilitiesScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen sosyal tesisler alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Lokasyon">
              <Controller
                control={methods.control}
                name="locationScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen lokasyon alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Yiyecek & İçecek">
              <Controller
                control={methods.control}
                name="foodScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen yiyecek & içecek alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Güvenlik">
              <Controller
                control={methods.control}
                name="safetyScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen güvenlik alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Kampüs">
              <Controller
                control={methods.control}
                name="campusScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen kampüs alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Görüşlerin" labelClassName="align-top" mobileFlex>
              <Textarea
                name="comment"
                rows="5"
                ref={methods.register({
                  required: 'Lütfen görüşlerin alanını doldurunuz!',
                  maxLength: {
                    value: COMMENT_MAX_LENGTH,
                    message: `Açıklamanız çok uzun. (Maksimum: ${COMMENT_MAX_LENGTH} karakter)`,
                  },
                })}
                charactersLeft={
                  COMMENT_MAX_LENGTH - methods.watch('comment')?.length
                }
                error={methods.errors.comment?.message}
              />
            </InfoItem>
            <InfoItem label=" " mobileFlex>
              <p className="pb-4">
                ‘Gönder’ butonuna tıklayarak{' '}
                <span className="font-bold underline">Gizlilik Politikası</span>
                ’nı,{' '}
                <span className="font-bold underline">Kullanım Koşulları</span>
                ’nı,{' '}
                <span className="font-bold underline">Çerez Politikası</span>
                ’nı ve{' '}
                <span className="font-bold underline">Aydınlatma Metni</span>
                ’ni kabul ediyorum. Gönderilen veri gönderim tarihinden itibaren
                ProfSözlük’e aittir.
              </p>
              <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
              <SubmitButton
                variant="contained"
                color="primary"
                size="large"
                status={isLoading ? 'disabled' : ''}
                className="mb-4"
              >
                {isLoading ? <Spinner color="white" /> : 'Gönder'}
              </SubmitButton>
              {error && (
                <Alert
                  type="error"
                  variant="contained"
                  message={error.message}
                />
              )}
              {isSuccess && (
                <>
                  <Alert
                    type="success"
                    variant="contained"
                    message="İşlem başarılı!"
                  />
                  <Redirect to={`/school/${id}`} />
                </>
              )}
            </InfoItem>
          </InfoField>
        </form>
      </FormProvider>
    </div>
  )
}

const RateSchoolPage = () => {
  const { id } = useParams()
  const { data, isLoading, error } = useSchool(id)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <SearchLayout>
      <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
        <div className="flex flex-col">
          <h2 className="text-2xl">Üniversite için yorum yap!</h2>
          <h1 className="py-4 text-4xl font-bold">
            <a href={`/school/${data.id}`}>{data.name}</a>
          </h1>
          <p className="text-base">{data.city}</p>
          <a
            href={data.website}
            target="_blank"
            rel="noreferrer"
            className="text-base font-bold underline"
          >
            {data.website}
          </a>
        </div>
        <RateSchoolForm />
        {/* <Ads /> */}
      </div>
    </SearchLayout>
  )
}

export default RateSchoolPage
