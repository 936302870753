import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'

import HomePage from './home.page'
import SearchResultsPage from './searchResults.page'
import LoginPage from './auth/login.page'
import RegisterPage from './auth/register.page'
import ForgotPasswordPage from './auth/forgotPassword.page'
import NewPasswordPage from './auth/newPassword.page'
import EmailVerificationPage from './auth/emailVerification.page'
import ProfessorDetailPage from './professor/professorDetail.page'
import RateProfessorPage from './professor/rateProfessor.page'
import CorrectProfessorPage from './professor/correctProfessor.page'
import SchoolDetailPage from './school/schoolDetail.page'
import RateSchoolPage from './school/rateSchool.page'
import CorrectSchoolPage from './school/correctSchool.page'
import LegalPage from './legal.page'
import AboutUsPage from './aboutUs.page'

const PublicRoutes = () => {
  const [token] = useLocalStorage('token')

  if (token) {
    return (
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/search" exact component={SearchResultsPage} />
        <Route path="/professor/:id" exact component={ProfessorDetailPage} />
        <Route path="/professor/:id/rate" exact component={RateProfessorPage} />
        <Route
          path="/professor/:id/correction"
          exact
          component={CorrectProfessorPage}
        />
        <Route path="/school/:id" exact component={SchoolDetailPage} />
        <Route path="/school/:id/rate" exact component={RateSchoolPage} />
        <Route
          path="/school/:id/correction"
          exact
          component={CorrectSchoolPage}
        />
        <Route path="/legal/:type" exact component={LegalPage} />
        <Route path="/about-us" exact component={AboutUsPage} />
        <Redirect to="/" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/login" exact component={LoginPage} />
      <Route path="/register" exact component={RegisterPage} />
      <Route path="/forgot-password" exact component={ForgotPasswordPage} />
      <Route
        path="/password-reset/:recoveryCode"
        exact
        component={NewPasswordPage}
      />
      <Route
        path="/verify/:verificationCode"
        exact
        component={EmailVerificationPage}
      />
      <Route path="/search" exact component={SearchResultsPage} />
      <Route path="/professor/:id" exact component={ProfessorDetailPage} />
      <Route path="/professor/:id/rate" exact component={RateProfessorPage} />
      <Route
        path="/professor/:id/correction"
        exact
        component={CorrectProfessorPage}
      />
      <Route path="/school/:id" exact component={SchoolDetailPage} />
      <Route path="/school/:id/rate" exact component={RateSchoolPage} />
      <Route
        path="/school/:id/correction"
        exact
        component={CorrectSchoolPage}
      />
      <Route path="/legal/:type" exact component={LegalPage} />
      <Route path="/about-us" exact component={AboutUsPage} />
      <Redirect to="/app" />
    </Switch>
  )
}

export default PublicRoutes
