import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'

const BUTTON_TYPE = {
  like: faThumbsUp,
  dislike: faThumbsDown,
}

const LikeButton = ({ type, commentId, hook, active, value, refetch }) => {
  const { mutateAsync } = hook(commentId)

  const onClick = () => mutateAsync().then(() => refetch())

  return (
    <button
      className={clsx(
        'space-x-1',
        active && type === 'like' && 'text-green-500',
        active && type === 'dislike' && 'text-red-500'
      )}
      type="button"
      onClick={onClick}
    >
      <FontAwesomeIcon icon={BUTTON_TYPE[type]} />
      <span className="font-semibold">{value}</span>
    </button>
  )
}

LikeButton.propTypes = {
  type: PropTypes.oneOf(['like', 'dislike']).isRequired,
  commentId: PropTypes.number.isRequired,
  hook: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
}

export default LikeButton
