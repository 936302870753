import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import useMyProfessorComments from 'hooks/professorComment/useMyProfessorComments'
import useMySchoolComments from 'hooks/schoolComment/useMySchoolComments'
import useMakeProfessorCommentLike from 'hooks/professorCommentLike/useMakeProfessorCommentLike'
import useMakeProfessorCommentDislike from 'hooks/professorCommentLike/useMakeProfessorCommentDislike'
import useMakeSchoolCommentLike from 'hooks/schoolCommentLike/useMakeSchoolCommentLike'
import useMakeSchoolCommentDislike from 'hooks/schoolCommentLike/useMakeSchoolCommentDislike'

import MainLayout from 'common/layouts/main.layout'
// import Ads from 'common/components/ads'
import LikeButton from 'common/views/app/likeButton.view'
import Pagination from 'common/components/pagination'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'
import InfoItem from 'common/components/infoItem'
import InfoField from 'common/components/infoField'

const ProfessorComment = ({
  id,
  courseCode,
  qualityScore,
  difficultyScore,
  takeAgain,
  mandatoryAttendance,
  takenForCredits,
  textbookUsage,
  online,
  comment,
  liked,
  disliked,
  likeCount,
  dislikeCount,
  professorId,
  professorFullName,
  schoolName,
  createdDate,
  refetch,
}) => (
  <div className="flex flex-col p-6 space-y-4 bg-white border border-gray-300 rounded">
    <div className="flex flex-col md:flex-row md:justify-between">
      <h1 className="text-base">
        <span className="font-bold">{professorFullName}</span> / {schoolName}
      </h1>
      <a
        href={`/app/professor/${professorId}/rate/${id}`}
        className="flex flex-row items-center space-x-2 pointer"
      >
        <FontAwesomeIcon icon={faEdit} />
        <p className="font-bold">Düzenle</p>
      </a>
    </div>
    <div className="border border-bodyBackground" />
    <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
      <div className="flex flex-row justify-center md:justify-start md:flex-col md:space-y-2">
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Kalite</h2>
          <h1 className="text-2xl font-bold">{qualityScore}</h1>
        </div>
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Zorluk</h2>
          <h1 className="text-2xl font-bold">{difficultyScore}</h1>
        </div>
      </div>
      <div className="flex flex-col flex-1 space-y-4">
        <div className="flex flex-row justify-between">
          <h1 className="text-base font-bold">{courseCode}</h1>
          <p className="text-sm font-medium text-secondary-500">
            {moment(createdDate).format('LL')}
          </p>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4">
          <p className="text-base font-medium">
            Tekrar Alırım:{' '}
            <span className="font-bold">{takeAgain ? 'Evet' : 'Hayır'}</span>
          </p>
          <p className="text-base font-medium">
            Katılım:{' '}
            <span className="font-bold">
              {mandatoryAttendance ? 'Zorunlu' : 'Zorunlu Değil'}
            </span>
          </p>
          <p className="text-base font-medium">
            Kredi İçin Aldım:{' '}
            <span className="font-bold">
              {takenForCredits ? 'Evet' : 'Hayır'}
            </span>
          </p>
          <p className="text-base font-medium">
            Kitap Kullanımı:{' '}
            <span className="font-bold">
              {textbookUsage ? 'Zorunlu' : 'Zorunlu Değil'}
            </span>
          </p>
          <p className="text-base font-medium">
            Uzaktan Eğitim:{' '}
            <span className="font-bold">{online ? 'Evet' : 'Hayır'}</span>
          </p>
        </div>
        <p className="text-base font-medium">{comment}</p>
        <div className="flex flex-row space-x-4 text-secondary-700">
          <LikeButton
            type="like"
            commentId={id}
            hook={useMakeProfessorCommentLike}
            active={liked}
            value={likeCount}
            refetch={refetch}
          />
          <LikeButton
            type="dislike"
            commentId={id}
            hook={useMakeProfessorCommentDislike}
            active={disliked}
            value={dislikeCount}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  </div>
)

ProfessorComment.propTypes = {
  id: PropTypes.number.isRequired,
  courseCode: PropTypes.string.isRequired,
  qualityScore: PropTypes.number.isRequired,
  difficultyScore: PropTypes.number.isRequired,
  takeAgain: PropTypes.bool.isRequired,
  mandatoryAttendance: PropTypes.bool.isRequired,
  takenForCredits: PropTypes.bool.isRequired,
  textbookUsage: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  liked: PropTypes.bool.isRequired,
  disliked: PropTypes.bool.isRequired,
  likeCount: PropTypes.number.isRequired,
  dislikeCount: PropTypes.number.isRequired,
  professorId: PropTypes.number.isRequired,
  professorFullName: PropTypes.string.isRequired,
  schoolName: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

const SchoolCommentItem = ({ label, score }) => (
  <div className="flex flex-row mb-6 ">
    <div className="flex-7 font-medium my-auto items-center">{label}</div>

    <div className="font-bold hover:justify-items-center ml-auto mr-8 flex-9 my-auto inline-block">
      {score}
    </div>
  </div>
)

SchoolCommentItem.propTypes = {
  label: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
}

const SchoolComment = ({
  id,
  reputationScore,
  opportunitiesScore,
  happinessScore,
  clubsScore,
  internetScore,
  facilitiesScore,
  locationScore,
  foodScore,
  safetyScore,
  campusScore,
  comment,
  liked,
  disliked,
  likeCount,
  dislikeCount,
  schoolId,
  schoolName,
  createdDate,
  refetch,
}) => (
  <div className="flex flex-col p-6  md:space-y-6 bg-white border border-gray-300 rounded">
    <div className="flex flex-col md:flex-row lg:space-y-4 md:justify-between ">
      <h1 className="text-base">
        <span className="font-bold">Derecelendirme</span> / {schoolName}
      </h1>
      <a
        href={`/app/school/${schoolId}/rate/${id}`}
        className="flex flex-row items-center space-x-2 pointer"
      >
        <FontAwesomeIcon icon={faEdit} />
        <p className="font-bold">Düzenle</p>
      </a>
    </div>
    <div className="border border-bodyBackground" />

    <div className="flex flex-col md:flex-row flex-1 md:space-x-6">
      <div className="flex flex-1 flex-col md:flex-row md:gap-4">
        <InfoField>
          <InfoItem
            label="İtibar"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {reputationScore}
          </InfoItem>
          <InfoItem
            label="Olanaklar"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {opportunitiesScore}
          </InfoItem>
          <InfoItem
            label="Mutluluk"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {happinessScore}
          </InfoItem>
          <InfoItem
            label="Öğrenci Toplulukları"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {clubsScore}
          </InfoItem>
          <InfoItem
            label="İnternet"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {internetScore}
          </InfoItem>
        </InfoField>
        <InfoField>
          <InfoItem
            label="Sosyal Tesisler"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {facilitiesScore}
          </InfoItem>
          <InfoItem
            label="Lokasyon"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {locationScore}
          </InfoItem>
          <InfoItem
            label="Yiyecek & İçecek"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {foodScore}
          </InfoItem>
          <InfoItem
            label="Güvenlik"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {safetyScore}
          </InfoItem>
          <InfoItem
            label="Kampüs"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {campusScore}
          </InfoItem>
        </InfoField>
      </div>
      <div className="border border-bodyBackground" />

      <div className="flex flex-col flex-1 space-y-4 lg:mt-0 md:mt-0 mt-6">
        <div className="flex flex-row justify-between">
          <h1 className="text-base font-bold">Yorum</h1>
          <p className="text-sm font-medium text-secondary-500">
            {moment(createdDate).format('LL')}
          </p>
        </div>
        <p className="text-base font-medium">{comment}</p>
        <div className="flex flex-row space-x-4 text-secondary-700">
          <LikeButton
            type="like"
            commentId={id}
            hook={useMakeSchoolCommentLike}
            active={liked}
            value={likeCount}
            refetch={refetch}
          />
          <LikeButton
            type="dislike"
            commentId={id}
            hook={useMakeSchoolCommentDislike}
            active={disliked}
            value={dislikeCount}
            refetch={refetch}
          />
        </div>
      </div>
    </div>
  </div>
)

SchoolComment.propTypes = {
  id: PropTypes.number.isRequired,
  reputationScore: PropTypes.number.isRequired,
  opportunitiesScore: PropTypes.number.isRequired,
  happinessScore: PropTypes.number.isRequired,
  clubsScore: PropTypes.number.isRequired,
  internetScore: PropTypes.number.isRequired,
  facilitiesScore: PropTypes.number.isRequired,
  locationScore: PropTypes.number.isRequired,
  foodScore: PropTypes.number.isRequired,
  safetyScore: PropTypes.number.isRequired,
  campusScore: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  liked: PropTypes.bool.isRequired,
  disliked: PropTypes.bool.isRequired,
  likeCount: PropTypes.number.isRequired,
  dislikeCount: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  schoolName: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

const MyRatingsPage = () => {
  const [professorCommentsPage, setProfessorCommentsPage] = useState(0)
  const [professorCommentsSize, setProfessorCommentsSize] = useState(10)
  const [schoolCommentsPage, setSchoolCommentsPage] = useState(0)
  const [schoolCommentsSize, setSchoolCommentsSize] = useState(10)
  const professorTitleRef = useRef(null)
  const schoolTitleRef = useRef(null)

  const {
    data: professorComments,
    isLoading: isProfessorCommentsLoading,
    error: professorCommentsError,
    refetch: refetchProfessorComments,
  } = useMyProfessorComments(professorCommentsPage, professorCommentsSize)
  const {
    data: schoolComments,
    isLoading: isSchoolCommentsLoading,
    error: schoolCommentsError,
    refetch: refetchSchoolComments,
  } = useMySchoolComments(schoolCommentsPage, schoolCommentsSize)

  const onProfessorCommentsPageChange = (page, size) => {
    setProfessorCommentsPage(page)
    setProfessorCommentsSize(size)
    professorTitleRef.current.scrollIntoView()
  }

  const onSchoolCommentsPageChange = (page, size) => {
    setSchoolCommentsPage(page)
    setSchoolCommentsSize(size)
    schoolTitleRef.current.scrollIntoView()
  }

  return (
    <MainLayout>
      <div className=" flex flex-col px-4 pt-8 pb-8 mx-auto space-y-8 md:pt-12 md:pb-20">
        <div className="space-y-4">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between">
            <h1 className="text-3xl font-bold" ref={professorTitleRef}>
              Öğretim Elemanı Gönderilerim
            </h1>
            <h4 className="text-base font-normal">
              {professorComments?.totalElements} yorum
            </h4>
          </div>
          {isProfessorCommentsLoading ? (
            <Spinner />
          ) : professorCommentsError ? (
            <Alert
              type="error"
              variant="contained"
              message={professorCommentsError.message}
            />
          ) : (
            <>
              {professorComments.content.map((comment) => (
                <ProfessorComment
                  key={comment.id}
                  refetch={refetchProfessorComments}
                  {...comment}
                />
              ))}
              <Pagination
                page={professorCommentsPage}
                size={professorCommentsSize}
                totalPages={professorComments.totalPages}
                isFirst={professorComments.first}
                isLast={professorComments.last}
                onPageChange={onProfessorCommentsPageChange}
              />
            </>
          )}
        </div>
        <div className="space-y-4">
          <div className="flex flex-col md:flex-row md:items-end md:justify-between">
            <h1 className="text-3xl font-bold" ref={schoolTitleRef}>
              Üniversite Gönderilerim
            </h1>
            <h4 className="text-base font-normal">
              {schoolComments?.totalElements} yorum
            </h4>
          </div>
          {isSchoolCommentsLoading ? (
            <Spinner />
          ) : schoolCommentsError ? (
            <Alert
              type="error"
              variant="contained"
              message={schoolCommentsError.message}
            />
          ) : (
            <>
              {schoolComments.content.map((comment) => (
                <SchoolComment
                  key={comment.id}
                  refetch={refetchSchoolComments}
                  {...comment}
                />
              ))}
              <Pagination
                page={schoolCommentsPage}
                size={schoolCommentsSize}
                totalPages={schoolComments.totalPages}
                isFirst={schoolComments.first}
                isLast={schoolComments.last}
                onPageChange={onSchoolCommentsPageChange}
              />
            </>
          )}
        </div>
        {/* <Ads /> */}
      </div>
    </MainLayout>
  )
}

export default MyRatingsPage
