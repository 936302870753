import { useMutation } from 'react-query'
import { createSchoolCorrection } from 'api/schoolCorrection.api'

export default (schoolId) =>
  useMutation(
    ({ data, captchaToken }) =>
      createSchoolCorrection(schoolId, data, captchaToken),
    {
      mutationKey: ['create-school-correction'],
    }
  )
