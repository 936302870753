import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useQuery from 'hooks/useQuery'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import Pagination from 'common/components/pagination'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'
import useSchools from 'hooks/school/useSchools'
import useProfessors from 'hooks/professor/useProfessors'

const ProfessorResult = ({ id, firstName, lastName, department, summary }) => (
  <Link to={`/professor/${id}`}>
    <div className="flex flex-row p-6 space-x-2 bg-white rounded">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Kalite</h2>
          <h1 className="text-2xl font-bold">
            {summary.qualityScoreAvg ?? '-'}
          </h1>
        </div>
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Zorluk</h2>
          <h1 className="text-2xl font-bold">
            {summary.difficultyScoreAvg ?? '-'}
          </h1>
        </div>
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        <h1 className="text-lg font-bold">{`${firstName} ${lastName}`}</h1>
        <h2 className="text-base">{department.name}</h2>
        <h3 className="text-sm">{department.school.name}</h3>
      </div>
    </div>
  </Link>
)

ProfessorResult.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  department: PropTypes.shape().isRequired,
  summary: PropTypes.shape().isRequired,
}

const SchoolResult = ({ id, name, city, summary }) => (
  <Link to={`/school/${id}`}>
    <div className="flex flex-row p-6 space-x-2 bg-white rounded">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Genel Derece</h2>
          <h1 className="text-2xl font-bold">{summary.commentCount ?? '-'}</h1>
        </div>
        <div className="flex flex-col px-4 text-center">
          <h2 className="text-sm font-medium">Prof. Derecesi</h2>
          <h1 className="text-2xl font-bold">-</h1>
        </div>
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        <h1 className="text-lg font-bold">{name}</h1>
        <h2 className="text-base">{city}</h2>
      </div>
    </div>
  </Link>
)

SchoolResult.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  summary: PropTypes.shape().isRequired,
}

const SearchProfessorView = ({ query }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const { data, isLoading, error } = useProfessors(query, page, size)

  const onPageChange = (_page, _size) => {
    setPage(_page)
    setSize(_size)
  }

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <>
      <p>
        İsminde <span className="font-bold">“{query}”</span> geçen{' '}
        <span className="font-bold">{data.totalElements}</span> profesör
        bulunmuştur.
      </p>
      <div className="space-y-4">
        {data.content.map((professor) => (
          <ProfessorResult key={professor.id} {...professor} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        totalPages={data.totalPages}
        isFirst={data.first}
        isLast={data.last}
        onPageChange={onPageChange}
      />
    </>
  )
}

SearchProfessorView.propTypes = {
  query: PropTypes.string.isRequired,
}

const SearchSchoolView = ({ query }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const { data, isLoading, error } = useSchools(query, page, size)

  const onPageChange = (_page, _size) => {
    setPage(_page)
    setSize(_size)
  }

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <>
      <p>
        İsminde <span className="font-bold">“{query}”</span> geçen{' '}
        <span className="font-bold">{data.totalElements}</span> üniversite
        bulunmuştur.
      </p>
      <div className="space-y-4">
        {data.content.map((school) => (
          <SchoolResult key={school.id} {...school} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        totalPages={data.totalPages}
        isFirst={data.first}
        isLast={data.last}
        onPageChange={onPageChange}
      />
    </>
  )
}

SearchSchoolView.propTypes = {
  query: PropTypes.string.isRequired,
}

const SearchResultsPage = () => {
  const query = useQuery()
  const type = query.get('type')
  const queryText = query.get('query')

  return (
    <SearchLayout>
      <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
        {/* <Ads /> */}
        {(!type || !query) && <p>Geçersiz sorgu!</p>}
        {type === 'professor' && <SearchProfessorView query={queryText} />}
        {type === 'school' && <SearchSchoolView query={queryText} />}
        {/* <Ads /> */}
      </div>
    </SearchLayout>
  )
}

export default SearchResultsPage
