import React from 'react'
import { Link } from 'react-router-dom'

import HamburgerMenu from 'common/views/common/hamburger.menu'
import MenuView from 'common/views/common/menu.view'

const HomeButton = () => (
  <Link
    to="/"
    className="flex flex-row items-center space-x-2 text-lg font-extrabold"
  >
    <img src="/logo.svg" alt="" className="h-4" />
    <div>
      <span className="text-primary-500">Prof</span>
      <span>Sözlük</span>
    </div>
  </Link>
)

const Header = () => (
  <>
    <header className="relative flex justify-between h-16 px-6 py-4 bg-white border-b border-grey-300 text-secondary-700">
      <HamburgerMenu />
      <HomeButton />
      <MenuView />
    </header>
  </>
)

export default Header
