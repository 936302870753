import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import useForgotPassword from 'hooks/auth/useForgotPassword'

import MainLayout from 'common/layouts/main.layout'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'

const ForgotPasswordForm = () => {
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useForgotPassword()

  const onSubmit = (data) => mutate(data)

  return (
    <div className="flex flex-col space-y-6 px-10 py-12 bg-white rounded-lg w-[448px] shadow-lg">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <h2 className="text-lg font-semibold text-center">Şifremi Unuttum</h2>
          <Input
            label="E-posta"
            type="email"
            name="email"
            ref={methods.register({
              required: 'Lütfen e-posta alanını doldurunuz!',
            })}
            error={methods.errors.email?.message}
          />
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            status={isLoading ? 'disabled' : ''}
          >
            {isLoading ? <Spinner color="white" /> : 'Şifremi Sıfırla'}
          </SubmitButton>
          {error && (
            <Alert type="error" variant="contained" message={error.message} />
          )}
          {isSuccess && (
            <Alert
              type="success"
              variant="contained"
              message="Size şifrenizi sıfırlamanız için bir email gönderdik, emaildeki linke tıklayarak şifre sıfırlama işlemini gerçekleştirebilirsiniz."
            />
          )}
        </form>
      </FormProvider>
    </div>
  )
}

const ForgotPasswordPage = () => (
  <MainLayout>
    <div className="flex justify-center px-4 py-8 md:py-20">
      <ForgotPasswordForm />
    </div>
  </MainLayout>
)

export default ForgotPasswordPage
