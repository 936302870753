import * as api from './api'

export const createProfessorCorrection = async (
  professorId,
  data,
  captchaToken
) =>
  api.post(
    `/professor/${professorId}/correction?captchaToken=${captchaToken}`,
    data
  )
