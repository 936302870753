import { useHistory } from 'react-router-dom'

export default () => {
  const history = useHistory()

  const signOut = () => {
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('userId')
    history.replace('/')
    window.location.reload(false)
  }

  return signOut
}
