import * as api from './api'

export const createProfessorComment = async (professorId, data, captchaToken) =>
  api.post(
    `/professor/${professorId}/comment?captchaToken=${captchaToken}`,
    data
  )

export const getProfessorComments = async (professorId, page = 0, size = 10) =>
  api.get(
    `/professor/${professorId}/comment?page=${page}&size=${size}&sort=createdDate,desc`
  )

export const getProfessorComment = async (professorId, id) =>
  api.get(`/professor/${professorId}/comment/${id}`)

export const updateProfessorComment = async (professorId, id, data) =>
  api.put(`/professor/${professorId}/comment/${id}`, data)

export const getMyProfessorComments = async (page = 0, size = 10) =>
  api.get(
    `/professor/comment/me?page=${page}&size=${size}&sort=createdDate,desc`
  )
