import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import ReactSelect, { components as reactSelectComponents } from 'react-select'
import { SearchIcon } from '@heroicons/react/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import Alert from '../alert'

const FLEX = {
  0: null,
  1: 'flex-1',
  2: 'flex-2',
  3: 'flex-3',
  4: 'flex-4',
  5: 'flex-5',
}

const DropdownIndicatorSearch = ({ ...props }) => (
  <reactSelectComponents.DropdownIndicator {...props}>
    <SearchIcon className="w-5 h-5" />
  </reactSelectComponents.DropdownIndicator>
)

const formatOptionLabel = ({ ...props }) => {
  const propperties = { ...props }
  return propperties.departmentName ? (
    <div className="flex flex-col">
      <span className="text-black font-bold">{propperties.label}</span>
      <div className="flex flex-row">
        <span className="text-gray-600">{propperties.departmentName}</span>
        <div className="w-1" />
        <FontAwesomeIcon icon={faCircle} className="h-1 w-1 mt-2" />
        <div className="w-1" />
        <span className="text-black italic">{propperties.school}</span>
      </div>
    </div>
  ) : (
    <span className="text-black font-bold">{propperties.label}</span>
  )
}

const customStyles = {
  control: (base, state) => ({
    ...base,
    // match with the menu
    borderRadius: state.isFocused ? '20px 20px 0 0' : 20,
  }),
  menu: (base) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
    hyphens: 'auto',
    // kill the gap
    marginTop: 0,
    textAlign: 'left',
    // prevent menu to scroll y
    wordWrap: 'break-word',
  }),
  menuList: (base) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
  }),
}

const Select = ({
  options,
  isMulti,
  isSearch,
  isLoading,
  filterOption,
  noOptionsMessage,
  id,
  label,
  placeholder,
  className,
  labelClassName,
  outerDivClassName,
  flex,
  size,
  required,
  components,
  menuPlacement,
  maxMenuHeight,
  setIsMenuOpen,
  value,
  onChange,
  error,
  ...props
}) => (
  <div
    className={clsx('flex flex-col space-y-2', FLEX[flex], outerDivClassName)}
  >
    {label && (
      <label
        htmlFor={id}
        className={clsx('font-bold space-x-1', labelClassName)}
      >
        <span>{label}</span>
        {required && <span className="text-danger-500">*</span>}
      </label>
    )}
    <ReactSelect
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      isMulti={isMulti}
      isLoading={isLoading}
      options={options}
      filterOption={filterOption}
      styles={customStyles}
      components={{
        ...components,
        DropdownIndicator: isSearch
          ? DropdownIndicatorSearch
          : reactSelectComponents.DropdownIndicator,
        IndicatorSeparator: null,
      }}
      value={value}
      onChange={onChange}
      className={className}
      menuPlacement={menuPlacement}
      maxMenuHeight={maxMenuHeight}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      isSearchable={isSearch}
      theme={(theme) => ({
        ...theme,
        colors: { ...theme.colors, primary25: '#F7CCCC', primary: '#D90000' },
      })}
      menuPortalTarget={document.querySelector('body')}
      {...props}
    />
    {error && <Alert type="error" message={error} />}
  </div>
)

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isMulti: PropTypes.bool,
  isSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  filterOption: PropTypes.func,
  noOptionsMessage: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  outerDivClassName: PropTypes.string,
  flex: PropTypes.number,
  size: PropTypes.oneOf(['medium', 'small', 'form']),
  required: PropTypes.bool,
  components: PropTypes.shape(),
  menuPlacement: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  setIsMenuOpen: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]),
  onChange: PropTypes.func,
  error: PropTypes.string,
}

Select.defaultProps = {
  isMulti: false,
  isSearch: false,
  isLoading: false,
  filterOption: undefined,
  noOptionsMessage: () => 'Bulunamadı',
  id: null,
  label: '',
  placeholder: '',
  className: '',
  labelClassName: '',
  outerDivClassName: '',
  flex: 1,
  size: 'medium',
  required: false,
  components: reactSelectComponents,
  menuPlacement: 'bottom',
  maxMenuHeight: 350,
  setIsMenuOpen: () => {},
  value: null,
  onChange: () => {},
  error: '',
}

export default Select
