import React from 'react'
import { Range } from 'react-range'
import PropTypes from 'prop-types'

const STEP = 1
const MIN = 1
const MAX = 5

const renderMark = ({ props }) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: '14px',
      width: '8px',
      backgroundColor: '#C6CCD5',
    }}
  />
)

renderMark.propTypes = {
  props: PropTypes.shape().isRequired,
}

const renderTrack = ({ props, children }) => (
  <div
    {...props}
    style={{
      ...props.style,
      height: '8px',
      width: '100%',
      backgroundColor: '#F1F2F5',
    }}
  >
    {children}
  </div>
)

renderTrack.propTypes = {
  props: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
}

const renderThumb = ({ props }) => (
  <div
    {...props}
    style={{
      ...props.style,
      borderRadius: '10px',
      height: '20px',
      width: '20px',
      backgroundColor: '#444D5A',
    }}
  />
)

renderThumb.propTypes = {
  props: PropTypes.shape().isRequired,
}

const Slider = ({ value, onChange }) => (
  <div className="flex flex-row items-center space-x-8">
    <span className="w-4 text-xl font-bold">{value}</span>
    <Range
      step={STEP}
      min={MIN}
      max={MAX}
      values={[value]}
      onChange={(values) => onChange(values[0])}
      renderMark={renderMark}
      renderTrack={renderTrack}
      renderThumb={renderThumb}
    />
  </div>
)

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Slider
