import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import useSchool from 'hooks/school/useSchool'
import useSchoolComment from 'hooks/schoolComment/useSchoolComment'
import useUpdateSchoolComment from 'hooks/schoolComment/useUpdateSchoolComment'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import InfoField from 'common/components/infoField'
import InfoItem from 'common/components/infoItem'
import Slider from 'common/components/inputs/slider'
import Textarea from 'common/components/inputs/textarea'
import SubmitButton from 'common/components/buttons/submitButton'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'

const RateSchoolForm = () => {
  const { schoolId, id } = useParams()
  const methods = useForm()
  const {
    data: schoolComment,
    isLoading: isSchoolCommentLoading,
    isSuccess: isSchoolCommentSuccess,
    error: schoolCommentError,
  } = useSchoolComment(schoolId, id)
  const {
    mutate,
    isLoading: isUpdateSchoolCommentLoading,
    isSuccess: isUpdateSchoolCommentSuccess,
    error: updateSchoolCommentError,
  } = useUpdateSchoolComment(schoolId, id)

  useEffect(() => {
    if (isSchoolCommentSuccess) {
      methods.reset(schoolComment)
    }
  }, [schoolComment])

  const onSubmit = (data) => mutate(data)

  return isSchoolCommentLoading ? (
    <Spinner />
  ) : schoolCommentError ? (
    <Alert
      type="error"
      variant="contained"
      message={schoolCommentError.message}
    />
  ) : (
    <div className="p-4 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <InfoField>
            <InfoItem label="İtibar" labelClassName="w-64">
              <Controller
                control={methods.control}
                name="reputationScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen itibar alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Olanaklar">
              <Controller
                control={methods.control}
                name="opportunitiesScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen olanaklar alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Mutluluk">
              <Controller
                control={methods.control}
                name="happinessScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen mutluluk alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Öğrenci Toplulukları">
              <Controller
                control={methods.control}
                name="clubsScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen öğrenci toplulukları alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="İnternet">
              <Controller
                control={methods.control}
                name="internetScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen internet alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Sosyal Tesisler">
              <Controller
                control={methods.control}
                name="facilitiesScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen sosyal tesisler alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Lokasyon">
              <Controller
                control={methods.control}
                name="locationScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen lokasyon alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Yiyecek & İçecek">
              <Controller
                control={methods.control}
                name="foodScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen yiyecek & içecek alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Güvenlik">
              <Controller
                control={methods.control}
                name="safetyScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen güvenlik alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Kampüs">
              <Controller
                control={methods.control}
                name="campusScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen kampüs alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Görüşlerin" labelClassName="align-top" mobileFlex>
              <Textarea
                name="comment"
                rows="5"
                ref={methods.register({
                  required: 'Lütfen görüşlerin alanını doldurunuz!',
                })}
                error={methods.errors.description?.message}
              />
            </InfoItem>
            <InfoItem label=" " mobileFlex>
              <p className="pb-4">
                ‘Düzenle’ butonuna tıklayarak{' '}
                <span className="font-bold underline">Gizlilik Politikası</span>
                ’nı,{' '}
                <span className="font-bold underline">Kullanım Koşulları</span>
                ’nı,{' '}
                <span className="font-bold underline">Çerez Politikası</span>
                ’nı ve{' '}
                <span className="font-bold underline">Aydınlatma Metni</span>
                ’ni kabul ediyorum. Gönderilen veri gönderim tarihinden itibaren
                ProfSözlük’e aittir.
              </p>
              <SubmitButton
                variant="contained"
                color="primary"
                size="large"
                status={isUpdateSchoolCommentLoading ? 'disabled' : ''}
                className="mb-4"
              >
                {isUpdateSchoolCommentLoading ? (
                  <Spinner color="white" />
                ) : (
                  'Düzenle'
                )}
              </SubmitButton>
              {updateSchoolCommentError && (
                <Alert
                  type="error"
                  variant="contained"
                  message={updateSchoolCommentError.message}
                />
              )}
              {isUpdateSchoolCommentSuccess && (
                <Alert
                  type="success"
                  variant="contained"
                  message="İşlem başarılı!"
                />
              )}
            </InfoItem>
          </InfoField>
        </form>
      </FormProvider>
    </div>
  )
}

const EditSchoolRatePage = () => {
  const { schoolId } = useParams()
  const { data, isLoading, error } = useSchool(schoolId)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <SearchLayout>
      <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
        <div className="flex flex-col">
          <h2 className="text-2xl">Üniversite yorumunu düzenle!</h2>
          <h1 className="py-4 text-4xl font-bold">{data.name}</h1>
          <p className="text-base">{data.city}</p>
          <a
            href={data.website}
            target="_blank"
            rel="noreferrer"
            className="text-base font-bold underline"
          >
            {data.website}
          </a>
        </div>
        <RateSchoolForm />
        {/* <Ads /> */}
      </div>
    </SearchLayout>
  )
}

export default EditSchoolRatePage
