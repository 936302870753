import * as api from './api'

export const getProfessors = async (query, page = 0, size = 10) =>
  api.get(
    `/professor?page=${page}&size=${size}&sort=created_date,desc${
      query ? `&query=${query}` : ''
    }`
  )

export const getProfessor = async (id) => api.get(`/professor/${id}`)
