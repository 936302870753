import React from 'react'
import { useParams } from 'react-router-dom'
import useQueryParams from 'hooks/useQueryParams'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckCircleIcon } from '@heroicons/react/outline'
import useNewPassword from 'hooks/auth/useNewPassword'

import MainLayout from 'common/layouts/main.layout'
import Button from 'common/components/buttons/button'
import SubmitButton from 'common/components/buttons/submitButton'
import Input from 'common/components/inputs/input'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'

const ForgotPasswordForm = () => {
  const { recoveryCode } = useParams()
  const queryParams = useQueryParams()
  const methods = useForm()

  const { mutate, isLoading, isSuccess, error } = useNewPassword()

  const onSubmit = (data) =>
    mutate({
      code: recoveryCode,
      email: queryParams.get('email'),
      newPassword: data.password,
    })

  return (
    <div className="flex flex-col space-y-6 px-10 py-12 bg-white rounded-lg w-[448px] shadow-lg">
      {isSuccess ? (
        <div className="flex flex-col space-y-6">
          <div className="flex items-center space-x-4 font-bold">
            <h2>Şifre oluşturma başarılı!</h2>
            <CheckCircleIcon className="w-7 h-7 text-success-500" />
          </div>
          <p>
            Giriş sayfasına gidebilir ve yeni şifreniz ile giriş yapabilirsiniz.
          </p>
          <Button
            href="/login"
            variant="contained"
            color="primary"
            size="large"
            // className="mt-auto"
          >
            Giriş Yap
          </Button>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-col space-y-6"
          >
            <h2 className="text-lg font-semibold text-center">
              Yeni Şifre Oluştur
            </h2>

            <Input
              label="Şifre"
              name="password"
              type="password"
              ref={methods.register({
                required: 'Lütfen şifre alanını doldurunuz!',
              })}
              error={methods.errors.password?.message}
            />
            <Input
              label="Şifre (tekrar)"
              name="passwordAgain"
              type="password"
              ref={methods.register({
                required: 'Lütfen şifre (tekrar) alanını doldurunuz!',
                validate: (value) =>
                  value === methods.watch('password') ||
                  'Tekrar girilen şifre doğru değil!',
              })}
              error={methods.errors.passwordAgain?.message}
            />

            <SubmitButton
              variant="contained"
              color="primary"
              size="large"
              status={isLoading ? 'disabled' : ''}
            >
              {isLoading ? <Spinner color="white" /> : 'Şifremi Sıfırla'}
            </SubmitButton>
            {error && (
              <Alert type="error" variant="contained" message={error.message} />
            )}
            {isSuccess && (
              <Alert
                type="success"
                variant="contained"
                message="İşlem başarılı!"
              />
            )}
          </form>
        </FormProvider>
      )}
    </div>
  )
}

const NewPasswordPage = () => (
  <MainLayout>
    <div className="flex justify-center px-4 py-8 md:py-20">
      <ForgotPasswordForm />
    </div>
  </MainLayout>
)

export default NewPasswordPage
