import React from 'react'
import PropTypes from 'prop-types'

const Radio = ({ label, id, value, onChange, checked, name }) => (
  <div className="flex items-center space-x-2">
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      className="w-5 h-5 border-2 text-primary-500 border-primary-500 focus-within:ring-0"
    />
    {label && (
      <label htmlFor={id} className="select-none text-h6">
        {label}
      </label>
    )}
  </div>
)

Radio.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  name: PropTypes.string,
}

Radio.defaultProps = {
  value: undefined,
  onChange: null,
  checked: false,
  name: null,
}

export default Radio
