import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const VARIANTS = {
  default: 'italic',
  contained: {
    error: `bg-danger-50`,
    success: `bg-success-50`,
    warning: `bg-success-50`,
  },
}

const TYPES = {
  error: 'text-danger-500',
  success: 'text-success-500',
  warning: 'text-warning-500',
}

const Alert = ({
  type,
  variant,
  message,
  startAdornment,
  endAdornment,
  className,
}) => (
  <div className="relative">
    {(startAdornment || endAdornment) && (
      <span
        className={clsx(
          'absolute inset-y-0 flex items-center',
          startAdornment && 'left-0 pl-3',
          endAdornment && 'right-0 pr-3'
        )}
      >
        {startAdornment || endAdornment}
      </span>
    )}
    <p
      role="alert"
      className={clsx(
        'text-sm',
        TYPES[type],
        variant === 'contained' && 'p-2 rounded font-semibold',
        VARIANTS[variant][type],
        startAdornment ? 'pl-10' : 'pl-2',
        endAdornment ? 'pr-10' : 'pr-2',
        className
      )}
    >
      <span>{message}</span>
    </p>
  </div>
)

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning']).isRequired,
  variant: PropTypes.oneOf(['default', 'contained']),
  message: PropTypes.string.isRequired,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  className: PropTypes.string,
}

Alert.defaultProps = {
  variant: 'default',
  startAdornment: null,
  endAdornment: null,
  className: '',
}

export default Alert
