import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

import Button from 'common/components/buttons/button'
import Dropdown from 'common/components/inputs/dropdown'

const SIZE_OPTIONS = [10, 25, 50]

const Pagination = ({
  page,
  size,
  totalPages,
  isFirst,
  isLast,
  onPageChange,
}) => {
  const methods = useForm()

  const onPrevPage = () => {
    methods.reset({ page })
    onPageChange(page - 1, size)
  }
  const onNextPage = () => {
    methods.reset({ page: page + 2 })
    onPageChange(page + 1, size)
  }

  return (
    <div className="flex flex-col items-center justify-between py-5 mt-6 space-y-2 rounded md:mt-auto lg:space-y-0 lg:flex-row text-secondary">
      <div className="flex items-center space-x-2 lg:space-x-4">
        <Button
          onClick={onPrevPage}
          variant="contained"
          color="secondary"
          status={isFirst ? 'disabled' : null}
          className="max-h-12"
        >
          Önceki
        </Button>
        <FormProvider {...methods}>
          <div className="flex items-center">
            Sayfa{' '}
            <Dropdown
              name="page"
              id="page"
              onChange={(e) => onPageChange(Number(e.target.value), size)}
              value={page}
              options={Array.from({ length: totalPages }, (_, i) => i).map(
                (e) => ({
                  text: (e + 1).toString(),
                  value: e,
                })
              )}
              shadowInner
            />
            / <span className="ml-2 font-bold">{totalPages}</span>
          </div>
        </FormProvider>
        <Button
          onClick={onNextPage}
          variant="contained"
          color="secondary"
          status={isLast ? 'disabled' : null}
          className="max-h-12"
        >
          Sonraki
        </Button>
      </div>
      <div className="flex items-center space-x-2">
        <p>Her sayfada</p>
        <Dropdown
          name="size"
          id="size"
          onChange={(e) => onPageChange(0, Number(e.target.value))}
          value={size}
          options={SIZE_OPTIONS.map((e) => ({
            text: e.toString(),
            value: e,
          }))}
          shadowInner
        />
        <p>sonuç göster</p>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  page: PropTypes.number,
  size: PropTypes.number,
  totalPages: PropTypes.number,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPageChange: PropTypes.func,
}

Pagination.defaultProps = {
  page: 0,
  size: 10,
  totalPages: 0,
  isFirst: false,
  isLast: false,
  onPageChange: () => {},
}

export default Pagination
