import * as api from './api'

export const createSchoolComment = async (schoolId, data, captchaToken) =>
  api.post(`/school/${schoolId}/comment?captchaToken=${captchaToken}`, data)

export const getSchoolComments = async (schoolId, page = 0, size = 10) =>
  api.get(
    `/school/${schoolId}/comment?page=${page}&size=${size}&sort=createdDate,desc`
  )

export const getSchoolComment = async (schoolId, id) =>
  api.get(`/school/${schoolId}/comment/${id}`)

export const updateSchoolComment = async (schoolId, id, data) =>
  api.put(`/school/${schoolId}/comment/${id}`, data)

export const getMySchoolComments = async (page = 0, size = 10) =>
  api.get(`/school/comment/me?page=${page}&size=${size}&sort=createdDate,desc`)
