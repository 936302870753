import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

import Alert from '../alert'
import Tooltip from '../tooltip'

const Textarea = React.forwardRef(
  (
    {
      label,
      id,
      name,
      value,
      onChange,
      placeholder,
      border,
      className,
      labelClassName,
      rows,
      tooltip,
      charactersLeft,
      error,
      textareaProps,
      ...props
    },
    ref
  ) => (
    <div className="flex flex-col flex-1 space-y-2">
      {label && (
        <label
          htmlFor={id}
          className={clsx(
            'font-bold flex items-center gap-x-1 w-full',
            labelClassName
          )}
        >
          <span>{label}</span>
          {tooltip && (
            <span>
              <QuestionMarkCircleIcon
                className="w-5 h-5 text-gray-500 cursor-help"
                data-for={tooltip.id}
                data-tip
              />
              <Tooltip id={tooltip.id}>{tooltip.content}</Tooltip>
            </span>
          )}
          {(charactersLeft || charactersLeft === 0) && (
            <span
              className={clsx(
                'ml-auto text-sm font-normal',
                charactersLeft >= 0 ? 'text-gray-400' : 'text-danger-500'
              )}
            >
              {charactersLeft} karakter kaldı
            </span>
          )}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={clsx(
          'w-full py-3 px-2 rounded ring-0 outline-none',
          className,
          border
            ? 'border border-light focus:border-primary-500'
            : 'border-none'
        )}
        rows={rows}
        placeholder={placeholder}
        {...textareaProps}
        ref={ref}
        {...props}
      />
      {error && <Alert type="error" message={error} />}
    </div>
  )
)

Textarea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  tooltip: PropTypes.shape(),
  charactersLeft: PropTypes.number,
  rows: PropTypes.string,
  error: PropTypes.string,
  textareaProps: PropTypes.shape(),
}

Textarea.defaultProps = {
  label: '',
  id: null,
  name: '',
  value: undefined,
  onChange: null,
  placeholder: '',
  border: true,
  className: '',
  labelClassName: '',
  tooltip: null,
  charactersLeft: null,
  rows: '15',
  error: '',
  textareaProps: null,
}

export default Textarea
