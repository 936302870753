import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import HamburgerMenu from 'common/views/common/hamburger.menu'
import MenuView from 'common/views/common/menu.view'
import ProfessorSearchBox from 'common/views/app/professorSearchBox.view'
import SchoolSearchBox from 'common/views/app/schoolSearchBox.view'
import Select from './inputs/select'

const HomeButton = () => (
  <Link
    to="/"
    className="flex flex-row items-center space-x-2 text-lg font-extrabold"
  >
    <img src="/logo.svg" alt="" className="h-4" />
    <div>
      <span className="text-primary-500">Prof</span>
      <span>Sözlük</span>
    </div>
  </Link>
)

const SEARCH_TYPES = [
  {
    label: 'Öğretim Elemanı',
    value: 'PROFESSOR',
  },
  {
    label: 'Üniversite',
    value: 'SCHOOL',
  },
]

const SearchSection = () => {
  const [searchType, setSearchType] = useState(SEARCH_TYPES[0])
  return (
    <div className="flex-row flex-1 hidden px-4 space-x-4 md:flex">
      <Select
        placeholder="Üniversite Ara"
        options={SEARCH_TYPES}
        value={searchType}
        onChange={setSearchType}
      />
      {searchType.value === 'PROFESSOR' ? (
        <ProfessorSearchBox />
      ) : (
        <SchoolSearchBox />
      )}
    </div>
  )
}

const Header = () => (
  <>
    <header className="relative flex justify-between h-16 px-6 py-4 bg-white border-b border-grey-300 text-secondary-700">
      <HamburgerMenu />
      <HomeButton />
      <SearchSection />
      <MenuView />
    </header>
  </>
)

export default Header
