import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

const ErrorPage = () => {
  const history = useHistory()

  useEffect(() => {
    localStorage.clear()
    setTimeout(() => {
      history.push('/')
    }, 1000)
  }, [])

  return (
    <h4>
      Bilinmeyen bir hata oluştu! Birazdan anasayfaya yönlendiriliyorsunuz.
    </h4>
  )
}

export default ErrorPage
