import axios from 'axios'
import config from 'config/config'
import getToken from 'utils/getToken'

export const get = async (location, headers) => {
  const token = getToken()

  return axios
    .get(config.BASE_URL + location, {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}

export const post = async (location, body, headers) => {
  const token = getToken()

  return axios
    .post(config.BASE_URL + location, body, {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}

export const postFormData = async (location, formData, headers) => {
  const token = getToken()

  return axios({
    method: 'post',
    url: config.BASE_URL + location,
    data: formData,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}

export const put = async (location, body, headers) => {
  const token = getToken()

  return axios
    .put(config.BASE_URL + location, body, {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}

export const patch = async (location, body, headers) => {
  const token = getToken()

  return axios
    .patch(config.BASE_URL + location, body, {
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}

export const del = async (location, body, headers) => {
  const token = getToken()

  return axios
    .delete(config.BASE_URL + location, {
      data: body,
      headers: {
        ...headers,
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data ? error.response.data : error.toJSON()
    })
}
