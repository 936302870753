import React, { Suspense } from 'react'
import config from 'config/config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CookieBanner } from '@palmabit/react-cookie-law'

import 'moment/locale/tr'
import '@fontsource/montserrat/100.css'
import '@fontsource/montserrat/200.css'
import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'
import '@fontsource/montserrat/800.css'
import '@fontsource/montserrat/900.css'
import 'react-toastify/dist/ReactToastify.css'

import Pages from 'pages'
import Spinner from 'common/components/spinner'
import ScrollToTop from 'common/components/scrollToTop'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const App = () => (
  <GoogleReCaptchaProvider
    language="tr-TR"
    reCaptchaKey={config.RECAPTCHA_SITE_KEY}
  >
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full h-screen">
          <Spinner size="4x" />
        </div>
      }
    >
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <Pages />
          <ToastContainer icon={false} limit={5} />
          <ReactQueryDevtools initialIsOpen={false} />
          <CookieBanner
            message="Bu site veya bu site tarafından kullanılan üçüncü taraf araçlar, işlem için gerekli ve çerez politikasında belirtilen amaçlar için faydalı olan çerezleri kullanır. Daha fazla bilgi edinmek için çerez politikasına bakın. Kabul ederek, çerezlerin kullanımına izin vermiş olursunuz."
            policyLink="/legal/cookie-policy"
            privacyPolicyLinkText="Çerez Politikası"
            necessaryOptionText="Gerekli"
            preferencesOptionText="Tercihler"
            statisticsOptionText="İstatistikler"
            marketingOptionText="Pazarlama"
            acceptButtonText="Kabul et"
            declineButtonText="Reddet"
            managePreferencesButtonText="Çerezleri yönet"
            savePreferencesButtonText="Kaydet"
            showMarketingOption={false}
            showPreferencesOption={false}
            showStatisticsOption={false}
            onAccept={() => {}}
            onAcceptPreferences={() => {}}
            onAcceptStatistics={() => {}}
            onAcceptMarketing={() => {}}
            styles={{
              dialog: {
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 10000,
                backgroundColor: 'rgb(248, 247, 247)',
                padding: 10,
              },
            }}
          />
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  </GoogleReCaptchaProvider>
)

export default App
