import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const COLORS = {
  primary: 'text-primary-500',
  white: 'text-white',
}

const Spinner = ({ size, color }) => (
  <div className="flex items-center justify-center">
    <FontAwesomeIcon
      icon={faSpinner}
      size={size}
      className={clsx('animate-spin', COLORS[color])}
    />
  </div>
)

Spinner.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

Spinner.defaultProps = {
  size: 'lg',
  color: 'primary',
}

export default Spinner
