import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useQueryParams from 'hooks/useQueryParams'
import useVerify from 'hooks/auth/useVerify'
import useSendVerificationEmail from 'hooks/auth/useSendVerificationEmail'
import { CheckCircleIcon, MailIcon } from '@heroicons/react/outline'

import MainLayout from 'common/layouts/main.layout'
import Button from 'common/components/buttons/button'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'

const EmailVerificationForm = () => {
  const { verificationCode } = useParams()
  const queryParams = useQueryParams()

  const {
    mutate: mutateVerify,
    isLoading: isVerifyLoading,
    error: verifyError,
  } = useVerify()
  const {
    mutate: mutateSendVerificationEmail,
    isLoading: isSendVerificationEmailLoading,
    isSuccess: isSendVerificationEmailSuccess,
    error: sendVerificationEmailError,
  } = useSendVerificationEmail()

  useEffect(() => {
    mutateVerify({ code: verificationCode, email: queryParams.get('email') })
  }, [])

  return (
    <div className="flex flex-col space-y-6 px-10 py-12 bg-white rounded-lg w-[448px] shadow-lg">
      {isVerifyLoading ? (
        <Spinner />
      ) : verifyError ? (
        isSendVerificationEmailSuccess ? (
          <div className="flex flex-col min-h-300">
            <div className="flex flex-col">
              <div className="flex items-center space-x-4 font-bold">
                <h2>Posta kutunuzu kontrol edin!</h2>
                <MailIcon className="w-7 h-7" />
              </div>
              <p className="mt-6">
                Size bir e-posta doğrulama bağlantısı gönderdik. Bulamazsanız
                spam klasörünü kontrol edebilirsiniz.
              </p>
            </div>
            <Button
              href="/login"
              variant="contained"
              color="primary"
              size="large"
              className="mt-auto"
            >
              Giriş Sayfasına Git
            </Button>
          </div>
        ) : (
          <div className="flex flex-col min-h-300">
            {sendVerificationEmailError ? (
              <Alert
                type="error"
                variant="contained"
                message={sendVerificationEmailError.message}
              />
            ) : (
              <Alert
                type="error"
                variant="contained"
                message={verifyError.message}
              />
            )}
            <div className="flex flex-col mt-auto space-y-6">
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() =>
                  mutateSendVerificationEmail({
                    email: queryParams.get('email'),
                  })
                }
                status={isSendVerificationEmailLoading ? 'disabled' : ''}
              >
                Yeni Doğrulama Bağlantısı Gönder
              </Button>
              <Button
                href="/login"
                variant="outlined"
                color="transparent"
                size="large"
                className="text-center"
                status={isSendVerificationEmailLoading ? 'disabled' : ''}
              >
                Giriş Sayfasına Git
              </Button>
            </div>
          </div>
        )
      ) : (
        <div className="flex flex-col min-h-300">
          <div className="flex flex-col">
            <div className="flex items-center space-x-4 font-bold">
              <h2>E-posta doğrulaması başarılı</h2>
              <CheckCircleIcon className="w-7 h-7 text-success-500" />
            </div>
            <p className="mt-6">
              E-posta adresinizi doğruladık, giriş yapabilir ve kayıt sürecine
              devam edebilirsiniz.
            </p>
          </div>
          <Button
            href="/login"
            variant="outlined"
            color="transparent"
            size="large"
            className="mt-auto text-center"
          >
            Giriş Sayfasına Git
          </Button>
        </div>
      )}
    </div>
  )
}

const EmailVerificationPage = () => (
  <MainLayout>
    <div className="flex justify-center px-4 py-8 md:py-20">
      <EmailVerificationForm />
    </div>
  </MainLayout>
)

export default EmailVerificationPage
