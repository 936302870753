import React from 'react'

import MainLayout from 'common/layouts/main.layout'

const AboutUsView = () => (
  <section className="space-y-4">
    <h1 className="font-semibold">Hakkımızda</h1>
    <p>
      Prof Sözlük, üniversiteler ve öğretim elemanları hakkında bilgi sahibi
      olmak için oluşturulan anonim bir yorum platformudur. Amacımız siteyi
      ziyaret eden üniversite ve üniversite adayı öğrencilerine ders ve
      üniversite seçimlerinde yardım etmektir. Kullanıcılar, öğretim
      elemanlarına veya üniversitelere yapılan yorumlardan edindikleri bilgiler
      sayesinde eğitim hayatları için daha detaylı ve objektif bilgiye sahip
      olacaklardır.
    </p>
    <p>
      Prof Sözlük&#39;te 8 farklı şehirden 42 üniversite ve toplam 28,309
      öğretim elemanı mevcuttur. Belirli zaman aralıklarıyla yeni üniversiteler
      ve öğretim elemanları eklenmeye devam edilecektir.
    </p>
    <p>
      Aldığınız derslerin öğretim elemanları ve/veya üniversiteniz hakkında
      yorum yapmak ve yapılan yorumları okumak için “Artık Not Verme Sırası
      Bende” deyin ve sitemizi ziyaret edin.
    </p>
    <h1 className="font-semibold">İletişim</h1>
    <p>
      Bize <a href="mailto:info@profsozluk.com">info@profsozluk.com</a>{' '}
      adresinden ulaşabilirsiniz.
    </p>
  </section>
)

const AboutUsPage = () => (
  <MainLayout>
    <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
      <AboutUsView />
      {/* <Ads /> */}
    </div>
  </MainLayout>
)

export default AboutUsPage
