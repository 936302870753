import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const STATUSES = {
  fetching: 'opacity-50 cursor-wait',
  disabled: 'opacity-50 cursor-not-allowed',
}

const SIZES = {
  large: 'px-8 py-3',
  medium: 'px-4 py-2',
  small: 'px-2 py-1',
}

const COLORS = {
  contained: {
    primary: 'bg-primary-500 hover:bg-primary-600 text-white',
    secondary: 'bg-secondary-500 hover:bg-secondary-600 text-white',
    danger: 'bg-danger-500 hover:bg-danger-600 text-white',
    success: 'bg-success-500 hover:bg-success-600 text-white',
    warning: 'bg-warning-500 hover:bg-warning-600 text-white',
    gray: 'bg-gray-500 hover:bg-gray-600 text-white',
  },
  outlined: {
    primary:
      'border border-primary-500 focus:border-primary-500 hover:bg-primary-500 text-primary-500 hover:text-white',
    secondary:
      'border border-secondary-500 focus:border-secondary-500 hover:bg-secondary-500 text-secondary-500 hover:text-white',
    danger:
      'border border-danger-500 focus:border-danger-500 hover:bg-danger-500 text-danger-500 hover:text-white',
    success:
      'border border-success-500 focus:border-success-500 hover:bg-success-500 text-success-500 hover:text-white',
    warning:
      'border border-warning-500 focus:border-warning-500 hover:bg-warning-500 text-warning-500 hover:text-white',
    gray: 'border border-gray-500 focus:border-gray-500 hover:bg-gray-500 text-gray-500 hover:text-white',
  },
  default: {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    danger: 'text-danger-500',
    success: 'text-success-500',
    warning: 'text-warning-500',
    gray: 'text-gray-500',
  },
}

const SubmitButton = ({
  children,
  variant,
  color,
  size,
  startAdornment,
  status,
  className,
}) => (
  <button
    type="submit"
    className={clsx(
      'flex items-center justify-center space-x-2 rounded font-medium',
      COLORS[variant][color],
      STATUSES[status],
      variant === 'contained' && 'text-white',
      variant !== 'default' && SIZES[size],
      variant === 'default' && 'hover:underline',
      className
    )}
  >
    {startAdornment}
    <span>{children}</span>
  </button>
)

SubmitButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  startAdornment: PropTypes.node,
  status: PropTypes.string,
  className: PropTypes.string,
}

SubmitButton.defaultProps = {
  variant: 'default',
  color: 'primary',
  size: 'medium',
  startAdornment: null,
  status: '',
  className: '',
}

export default SubmitButton
