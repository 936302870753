import React from 'react'
import PropTypes from 'prop-types'
import Alert from '../alert'

const Checkbox = React.forwardRef(
  ({ label, id, name, isChecked, onChange, error }, ref) => (
    <div className="flex flex-col flex-1 space-y-2">
      <div className="flex items-center space-x-3">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={isChecked}
          onChange={onChange}
          className="w-5 h-5 border-2 rounded text-primary-500 border-light checked:border-primary-500 focus-within:ring-0"
          ref={ref}
        />
        {label && (
          <label htmlFor={id} className="w-full select-none text-h6">
            {label}
          </label>
        )}
      </div>
      {error && <Alert type="error" message={error} />}
    </div>
  )
)

Checkbox.propTypes = {
  label: PropTypes.node,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.string,
}

Checkbox.defaultProps = {
  label: null,
  name: null,
  isChecked: null,
  onChange: null,
  error: null,
}

export default Checkbox
