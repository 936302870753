import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import useLogin from 'hooks/auth/useLogin'

import MainLayout from 'common/layouts/main.layout'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'

const LoginForm = () => {
  const methods = useForm()
  const { mutate, isLoading, error } = useLogin()

  const onSubmit = (data) => mutate(data)

  return (
    <div className="flex flex-col space-y-6 px-10 py-12 bg-white rounded-lg w-[448px] shadow-lg">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <h2 className="text-lg font-semibold text-center">Giriş Yap</h2>
          <Input
            label="E-posta"
            type="email"
            name="email"
            ref={methods.register({
              required: 'Lütfen e-posta alanını doldurunuz!',
            })}
            error={methods.errors.email?.message}
          />
          <Input
            label="Şifre"
            name="password"
            type="password"
            ref={methods.register({
              required: 'Lütfen şifre alanını doldurunuz!',
            })}
            error={methods.errors.password?.message}
          />
          <SubmitButton
            variant="contained"
            color="primary"
            size="large"
            status={isLoading ? 'disabled' : ''}
          >
            {isLoading ? <Spinner color="white" /> : 'Giriş Yap'}
          </SubmitButton>
          {error && (
            <Alert type="error" variant="contained" message={error.message} />
          )}
        </form>
        <Link to="/forgot-password" className="text-xs font-semibold">
          Şifremi unuttum
        </Link>
        <div className="border-b border-light" />
        <span className="py-2 text-sm text-center">veya</span>
        <Link to="/register" className="font-bold text-center">
          Kayıt Ol
        </Link>
      </FormProvider>
    </div>
  )
}

const LoginPage = () => (
  <MainLayout>
    <div
      className="bg-center bg-cover"
      style={{ backgroundImage: "url('/images/login-hero.png')" }}
    >
      <div className="bg-black/[0.5] flex flex-row space-x-24">
        <div className="container flex flex-row px-4 pt-8 pb-8 md:pt-20 md:pb-[160px] mx-auto justify-evenly">
          <h1 className="w-[540px] text-5xl font-extrabold pt-16 leading-[1.2] text-white hidden md:flex">
            Profesörleri ve üniversiteleri yorumlamak için hemen giriş yap!
          </h1>
          <LoginForm />
        </div>
      </div>
    </div>
  </MainLayout>
)

export default LoginPage
