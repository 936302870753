import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import useProfessor from 'hooks/professor/useProfessor'
import useCreateProfessorCorrection from 'hooks/professorCorrection/useCreateProfessorCorrection'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import InfoField from 'common/components/infoField'
import InfoItem from 'common/components/infoItem'
import Input from 'common/components/inputs/input'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'
import Dropdown from 'common/components/inputs/dropdown'

const CORRECTION_TYPES = [
  {
    text: 'İsim Tekrarı',
    value: 'İsim Tekrarı',
  },
  {
    text: 'Yazım Hatası',
    value: 'Yazım Hatası',
  },
  {
    text: 'Yanlış Üniversite',
    value: 'Yanlış Üniversite',
  },
  {
    text: 'Yanlış Bölüm',
    value: 'Yanlış Bölüm',
  },
  {
    text: 'Diğer',
    value: 'Diğer',
  },
]

const CorrectProfessorForm = () => {
  const { id } = useParams()
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } =
    useCreateProfessorCorrection(id)

  const [captchaToken, setCaptchaToken] = useState()

  const onSubmit = (data) => mutate({ data, captchaToken })

  return (
    <div className="p-4 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <InfoField>
            <InfoItem label="Problem nedir?" labelClassName="w-64">
              <Dropdown
                id="type"
                name="type"
                options={CORRECTION_TYPES}
                ref={methods.register({
                  required: 'Lütfen problem alanını doldurunuz!',
                })}
                error={methods.errors.type?.message}
              />
            </InfoItem>
            <InfoItem label="Doğru bilgi nedir?" labelClassName="w-64">
              <Input
                type="text"
                name="correctInfo"
                ref={methods.register({
                  required: 'Lütfen doğru bilgi alanını doldurunuz!',
                })}
                error={methods.errors.correctInfo?.message}
              />
            </InfoItem>
            <InfoItem label=" ">
              <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
              <SubmitButton
                variant="contained"
                color="primary"
                size="large"
                status={isLoading ? 'disabled' : ''}
                className="mb-4"
              >
                {isLoading ? <Spinner color="white" /> : 'Gönder'}
              </SubmitButton>
              {error && (
                <Alert
                  type="error"
                  variant="contained"
                  message={error.message}
                />
              )}
              {isSuccess && (
                <Alert
                  type="success"
                  variant="contained"
                  message="İşlem başarılı!"
                />
              )}
            </InfoItem>
          </InfoField>
        </form>
      </FormProvider>
    </div>
  )
}

const CorrectProfessorPage = () => {
  const { id } = useParams()
  const { data, isLoading, error } = useProfessor(id)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <SearchLayout>
      <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
        <h1 className="text-4xl">
          <span className="font-bold">
            <a href={`/professor/${data.id}`}>
              {data.firstName} {data.lastName}
            </a>
          </span>{' '}
          için hata bildir!
        </h1>
        <CorrectProfessorForm />
        {/* <Ads /> */}
      </div>
    </SearchLayout>
  )
}

export default CorrectProfessorPage
