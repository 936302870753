import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { InformationCircleIcon } from '@heroicons/react/solid'

const Tooltip = ({ children, id }) => (
  <ReactTooltip
    id={id}
    effect="solid"
    className="flex max-w-xs font-medium shadow-lg"
    backgroundColor="#f4f4f4"
    textColor="#495B7D"
  >
    <InformationCircleIcon className="w-6 h-6 mr-3" />
    <div className="flex-1">{children}</div>
  </ReactTooltip>
)

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
}

export default Tooltip
