import React from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBalanceScale,
  faBookOpen,
  faHome,
} from '@fortawesome/free-solid-svg-icons'
import { MenuIcon } from '@heroicons/react/outline'

import useComponentVisible from 'hooks/useComponentVisible'

const NavbarItemsView = () => {
  const location = useLocation()

  const navItems = [
    {
      id: 1,
      href: '/',
      label: 'Anasayfa',
      icon: faHome,
    },
    {
      id: 1,
      href: '/about-us',
      label: 'Hakkımızda',
      icon: faBookOpen,
    },
    {
      id: 1,
      href: '/legal/user-agreement',
      label: 'Kullanım Koşulları',
      icon: faBalanceScale,
    },
  ]

  return (
    <nav>
      <ul className="flex flex-col space-y-4">
        {navItems.map((item) => (
          <li key={item.id}>
            <Link to={item.href}>
              <div
                className={clsx(
                  'flex items-center space-x-3',
                  location.pathname === item.href
                    ? 'text-primary-500 font-bold'
                    : 'font-medium'
                )}
              >
                <div className="flex items-center justify-center w-6 h-6">
                  <FontAwesomeIcon icon={item.icon} />
                </div>
                <span>{item.label}</span>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

const HamburgerMenu = () => {
  const {
    ref: navbarItemsRef,
    isComponentVisible: isNavbarItemsVisible,
    setIsComponentVisible: setNavbarItemsVisible,
  } = useComponentVisible(false)

  return (
    <div className="flex md:hidden" ref={navbarItemsRef} aria-hidden>
      <MenuIcon
        className="w-6 h-6 cursor-pointer"
        onClick={() => setNavbarItemsVisible(!isNavbarItemsVisible)}
      />
      {isNavbarItemsVisible && (
        <div className="absolute left-0 z-50 w-full h-screen px-6 py-6 bg-white top-16">
          <NavbarItemsView />
        </div>
      )}
    </div>
  )
}

export default HamburgerMenu
