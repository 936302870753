import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const InfoItem = ({
  children,
  label,
  labelClassName,
  textClassName,
  mobileFlex,
}) => (
  <tr>
    {label && (
      <td
        className={clsx(
          'font-semibold',
          mobileFlex ? 'pb-2 sm:pb-4 sm:pr-8 hidden md:block' : 'pb-4 pr-8',
          labelClassName
        )}
      >
        {label}
      </td>
    )}
    {mobileFlex ? (
      <td colSpan={2} className={clsx('pb-4', textClassName)}>
        <div className="flex flex-col lg:flex:row">
          <div className="visible md:hidden lg:hidden font-semibold">
            {label}
          </div>
          {children}
        </div>
      </td>
    ) : (
      <td className={clsx('pb-4 w-full', textClassName)}>{children}</td>
    )}
  </tr>
)

InfoItem.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  labelClassName: PropTypes.string,
  textClassName: PropTypes.string,
  mobileFlex: PropTypes.bool,
}

InfoItem.defaultProps = {
  children: null,
  label: null,
  labelClassName: '',
  textClassName: '',
  mobileFlex: false,
}

export default InfoItem
