import * as api from './api'

export const getSchools = async (query, page = 0, size = 10) =>
  api.get(
    `/school?page=${page}&size=${size}&sort=created_date,desc${
      query ? `&query=${query}` : ''
    }`
  )

export const getSchool = async (id) => api.get(`/school/${id}`)
