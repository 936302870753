import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import useProfessor from 'hooks/professor/useProfessor'
import useProfessorComments from 'hooks/professorComment/useProfessorComments'
import useMakeProfessorCommentLike from 'hooks/professorCommentLike/useMakeProfessorCommentLike'
import useMakeProfessorCommentDislike from 'hooks/professorCommentLike/useMakeProfessorCommentDislike'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import Button from 'common/components/buttons/button'
import LikeButton from 'common/views/app/likeButton.view'
import Pagination from 'common/components/pagination'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'
import useUserMe from 'hooks/user/useUserMe'
import getToken from 'utils/getToken'

const ProfessorInfo = () => {
  const { id } = useParams()
  const token = getToken()
  const { isSuccess: isUserMeSuccess } = useUserMe({ enabled: !!token })
  const { data, isLoading, error } = useProfessor(id)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-8 md:items-center md:flex-row">
      <div className="flex flex-col flex-1 space-y-4">
        <h1 className="text-2xl font-bold md:text-5xl">
          {data.firstName}
          <br />
          {data.lastName}
        </h1>
        <div>
          <a
            href={`/school/${data.department.school.id}`}
            className="text-base font-bold underline"
          >
            {data.department.school.name}
          </a>
          <p className="text-base">{data.department.name}</p>
        </div>
        {isUserMeSuccess ? (
          <Button
            variant="contained"
            href={`/professor/${id}/rate`}
            className="font-bold"
          >
            Yorum Yap
          </Button>
        ) : (
          <p className="text-center">Yorum yapmak için lütfen giriş yapınız.</p>
        )}
        <Button
          variant="default"
          href={`/professor/${id}/correction`}
          className="font-bold"
        >
          Hata Bildir
        </Button>
      </div>
      <div className="flex flex-col flex-1 space-y-4 text-center">
        {data.summary ? (
          <>
            <div className="flex flex-row justify-evenly">
              <div className="flex flex-col space-y-2">
                <h2 className="text-3xl font-bold">Kalite</h2>
                <h1 className="text-6xl font-bold">
                  {data.summary.qualityScoreAvg.toFixed(1)}
                  <span className="text-4xl font-bold text-secondary-500">
                    /5
                  </span>
                </h1>
              </div>
              <div className="flex flex-col space-y-2">
                <h2 className="text-3xl font-bold">Zorluk</h2>
                <h1 className="text-6xl font-bold">
                  {data.summary.difficultyScoreAvg.toFixed(1)}
                  <span className="text-4xl font-bold text-secondary-500">
                    /5
                  </span>
                </h1>
              </div>
            </div>
            <p>
              Derecelendirmeler{' '}
              <span className="font-bold underline">
                {data.summary.commentCount} yorum
              </span>{' '}
              ortalaması üzerinden hesaplanmıştır.
            </p>
          </>
        ) : (
          <p>Hiç yorum bulunmamaktadır.</p>
        )}
      </div>
      {/* <Ads /> */}
    </div>
  )
}

const ProfessorComments = () => {
  const { id } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const titleRef = useRef(null)
  const { data, isLoading, error, refetch } = useProfessorComments(
    id,
    page,
    size
  )

  const onPageChange = (_page, _size) => {
    if (_page >= 0) {
      setPage(_page)
      setSize(_size)
      titleRef.current.scrollIntoView()
    }
  }

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <div className="py-4 space-y-4">
      <div className="flex flex-row items-end justify-between">
        <h1 className="text-2xl font-bold" ref={titleRef}>
          Gönderiler
        </h1>
        <h4 className="text-base font-normal">{data.totalElements} yorum</h4>
      </div>
      <div className="flex flex-col space-y-4">
        {data.content.map((comment) => (
          <ProfessorComment key={comment.id} {...comment} refetch={refetch} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        totalPages={data.totalPages}
        isFirst={data.first}
        isLast={data.last}
        onPageChange={onPageChange}
      />
    </div>
  )
}

const ProfessorComment = ({
  id,
  courseCode,
  qualityScore,
  difficultyScore,
  takeAgain,
  mandatoryAttendance,
  takenForCredits,
  textbookUsage,
  online,
  comment,
  liked,
  disliked,
  likeCount,
  dislikeCount,
  createdDate,
  refetch,
}) => (
  <div className="flex flex-col p-6 bg-white border border-gray-300 rounded md:space-x-2 md:flex-row">
    <div className="flex flex-row justify-center md:justify-start md:space-y-2 md:flex-col">
      <div className="flex flex-col px-4 text-center">
        <h2 className="text-sm font-medium">Kalite</h2>
        <h1 className="text-2xl font-bold">{qualityScore}</h1>
      </div>
      <div className="flex flex-col px-4 text-center">
        <h2 className="text-sm font-medium">Zorluk</h2>
        <h1 className="text-2xl font-bold">{difficultyScore}</h1>
      </div>
    </div>
    <div className="flex flex-col flex-1 space-y-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-base font-bold">{courseCode}</h1>
        <p className="text-sm font-medium text-secondary-500">
          {moment(createdDate).format('LL')}
        </p>
      </div>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <p className="text-base font-medium">
          Tekrar Alırım:{' '}
          <span className="font-bold">{takeAgain ? 'Evet' : 'Hayır'}</span>
        </p>
        <p className="text-base font-medium">
          Katılım:{' '}
          <span className="font-bold">
            {mandatoryAttendance ? 'Zorunlu' : 'Zorunlu Değil'}
          </span>
        </p>
        <p className="text-base font-medium">
          Kredi İçin Aldım:{' '}
          <span className="font-bold">
            {takenForCredits ? 'Evet' : 'Hayır'}
          </span>
        </p>
        <p className="text-base font-medium">
          Kitap Kullanımı:{' '}
          <span className="font-bold">
            {textbookUsage ? 'Zorunlu' : 'Zorunlu Değil'}
          </span>
        </p>
        <p className="text-base font-medium">
          Uzaktan Eğitim:{' '}
          <span className="font-bold">{online ? 'Evet' : 'Hayır'}</span>
        </p>
      </div>
      <p className="text-base font-medium">{comment}</p>
      <div className="flex flex-row space-x-4 text-secondary-700">
        <LikeButton
          type="like"
          commentId={id}
          hook={useMakeProfessorCommentLike}
          active={liked}
          value={likeCount}
          refetch={refetch}
        />
        <LikeButton
          type="dislike"
          commentId={id}
          hook={useMakeProfessorCommentDislike}
          active={disliked}
          value={dislikeCount}
          refetch={refetch}
        />
      </div>
    </div>
  </div>
)

ProfessorComment.propTypes = {
  id: PropTypes.number.isRequired,
  courseCode: PropTypes.string.isRequired,
  qualityScore: PropTypes.number.isRequired,
  difficultyScore: PropTypes.number.isRequired,
  takeAgain: PropTypes.bool.isRequired,
  mandatoryAttendance: PropTypes.bool.isRequired,
  takenForCredits: PropTypes.bool.isRequired,
  textbookUsage: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  liked: PropTypes.bool.isRequired,
  disliked: PropTypes.bool.isRequired,
  likeCount: PropTypes.number.isRequired,
  dislikeCount: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

const ProfessorDetailPage = () => (
  <SearchLayout>
    <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
      <ProfessorInfo />
      {/* <Ads /> */}
      <ProfessorComments />
      {/* <Ads /> */}
    </div>
  </SearchLayout>
)

export default ProfessorDetailPage
