import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import useSchool from 'hooks/school/useSchool'
import useSchoolComments from 'hooks/schoolComment/useSchoolComments'
import useMakeSchoolCommentLike from 'hooks/schoolCommentLike/useMakeSchoolCommentLike'
import useMakeSchoolCommentDislike from 'hooks/schoolCommentLike/useMakeSchoolCommentDislike'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import Button from 'common/components/buttons/button'
import LikeButton from 'common/views/app/likeButton.view'
import InfoField from 'common/components/infoField'
import InfoItem from 'common/components/infoItem'
import Pagination from 'common/components/pagination'
import Spinner from 'common/components/spinner'
import Alert from 'common/components/alert'
import useUserMe from 'hooks/user/useUserMe'
import getToken from 'utils/getToken'

const SchoolInfo = () => {
  const { id } = useParams()
  const token = getToken()
  const { isSuccess: isUserMeSuccess } = useUserMe({ enabled: !!token })
  const { data, isLoading, error } = useSchool(id)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-4 md:items-center md:space-y-0 md:flex-row md:space-x-8">
        <div className="flex flex-col space-y-4 flex-2">
          <h1 className="text-2xl font-bold md:text-5xl">{data.name}</h1>
          <div>
            <p className="text-base">{data.city}</p>
            <a
              href={data.website}
              target="_blank"
              rel="noreferrer"
              className="text-base font-bold underline"
            >
              {data.website}
            </a>
          </div>
          {isUserMeSuccess ? (
            <Button
              variant="contained"
              href={`/school/${id}/rate`}
              className="w-full md:w-[400px] font-bold"
            >
              Yorum Yap
            </Button>
          ) : (
            <p>Yorum yapmak için lütfen giriş yapınız.</p>
          )}
          {/* <Button
            variant="default"
            href={`/school/${id}/correction`}
            className="w-full md:w-[400px] font-bold"
          >
            Hata Bildir
          </Button> */}
        </div>
        {/* <Ads /> */}
      </div>
      <div className="flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4 md:flex-row">
        {data.summary ? (
          <>
            <div className="flex flex-col flex-1 space-y-4">
              <h1 className="text-base font-bold">Derecelendirme</h1>
              <div className="flex flex-col md:flex-row md:space-x-4">
                <InfoField>
                  <InfoItem
                    label="İtibar"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.reputationScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Olanaklar"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.opportunitiesScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Mutluluk"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.happinessScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Öğrenci Toplulukları"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.clubsScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="İnternet"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.internetScoreAvg.toFixed(1)}
                  </InfoItem>
                </InfoField>
                <InfoField>
                  <InfoItem
                    label="Sosyal Tesisler"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.facilitiesScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Lokasyon"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.locationScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Yiyecek & İçecek"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.foodScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Güvenlik"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.safetyScoreAvg.toFixed(1)}
                  </InfoItem>
                  <InfoItem
                    label="Kampüs"
                    labelClassName="font-medium w-full"
                    textClassName="font-bold"
                  >
                    {data.summary.campusScoreAvg.toFixed(1)}
                  </InfoItem>
                </InfoField>
              </div>
              <p>
                Derecelendirmeler{' '}
                <span className="font-bold underline">
                  {data.summary.schoolCommentCount} yorum
                </span>{' '}
                ortalaması üzerinden hesaplanmıştır.
              </p>
            </div>
            <div className="flex flex-col flex-1 space-y-4 text-center">
              <div className="flex flex-row justify-evenly">
                <div className="flex flex-col space-y-2">
                  <h2 className="text-3xl font-bold">
                    Genel
                    <br />
                    Derece
                  </h2>
                  <h1 className="text-6xl font-bold">
                    {data.summary.overallAvg.toFixed(1)}
                    <span className="text-4xl font-bold text-secondary-500">
                      /5
                    </span>
                  </h1>
                </div>
                <div className="flex flex-col space-y-2">
                  <h2 className="text-3xl font-bold">
                    Prof.
                    <br />
                    Derecesi
                  </h2>
                  <h1 className="text-6xl font-bold">
                    {data.summary.profAvg.toFixed(1)}
                    <span className="text-4xl font-bold text-secondary-500">
                      /5
                    </span>
                  </h1>
                </div>
              </div>
              <p>
                Prof. derecesi üniversiteye bağlı olan öğretim elemanları ile{' '}
                <span className="font-bold underline">
                  {data.summary.profCommentCount} yorum
                </span>{' '}
                ortalaması üzerinden hesaplanmıştır.
              </p>
            </div>
          </>
        ) : (
          <p>Hiç yorum bulunmamaktadır.</p>
        )}
      </div>
    </div>
  )
}

const SchoolComments = () => {
  const { id } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const titleRef = useRef(null)
  const { data, isLoading, error, refetch } = useSchoolComments(id, page, size)

  const onPageChange = (_page, _size) => {
    if (_page >= 0) {
      setPage(_page)
      setSize(_size)
      titleRef.current.scrollIntoView()
    }
  }

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <div className="py-4 space-y-4">
      <div className="flex flex-row items-end justify-between">
        <h1 className="text-2xl font-bold" ref={titleRef}>
          Gönderiler
        </h1>
        <h4 className="text-base font-normal">{data.totalElements} yorum</h4>
      </div>
      <div className="flex flex-col space-y-4">
        {data.content.map((comment) => (
          <SchoolComment key={comment.id} {...comment} refetch={refetch} />
        ))}
      </div>
      <Pagination
        page={page}
        size={size}
        totalPages={data.totalPages}
        isFirst={data.first}
        isLast={data.last}
        onPageChange={onPageChange}
      />
    </div>
  )
}

const SchoolComment = ({
  id,
  reputationScore,
  opportunitiesScore,
  happinessScore,
  clubsScore,
  internetScore,
  facilitiesScore,
  locationScore,
  foodScore,
  safetyScore,
  campusScore,
  comment,
  liked,
  disliked,
  likeCount,
  dislikeCount,
  createdDate,
  refetch,
}) => (
  <div className="flex flex-col p-6 space-y-6 bg-white border border-gray-300 rounded md:flex-row md:space-x-6">
    <div className="flex flex-col flex-1 space-y-4">
      <h1 className="text-base font-bold">Derecelendirme</h1>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <InfoField>
          <InfoItem
            label="İtibar"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {reputationScore}
          </InfoItem>
          <InfoItem
            label="Olanaklar"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {opportunitiesScore}
          </InfoItem>
          <InfoItem
            label="Mutluluk"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {happinessScore}
          </InfoItem>
          <InfoItem
            label="Öğrenci Toplulukları"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {clubsScore}
          </InfoItem>
          <InfoItem
            label="İnternet"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {internetScore}
          </InfoItem>
        </InfoField>
        <InfoField>
          <InfoItem
            label="Sosyal Tesisler"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {facilitiesScore}
          </InfoItem>
          <InfoItem
            label="Lokasyon"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {locationScore}
          </InfoItem>
          <InfoItem
            label="Yiyecek & İçecek"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {foodScore}
          </InfoItem>
          <InfoItem
            label="Güvenlik"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {safetyScore}
          </InfoItem>
          <InfoItem
            label="Kampüs"
            labelClassName="font-medium w-full"
            textClassName="font-bold"
          >
            {campusScore}
          </InfoItem>
        </InfoField>
      </div>
    </div>
    <div className="border border-bodyBackground" />
    <div className="flex flex-col flex-1 space-y-4">
      <div className="flex flex-row justify-between">
        <h1 className="text-base font-bold">Yorum</h1>
        <p className="text-sm font-medium text-secondary-500">
          {moment(createdDate).format('LL')}
        </p>
      </div>
      <p className="text-base font-medium">{comment}</p>
      <div className="flex flex-row space-x-4 text-secondary-700">
        <LikeButton
          type="like"
          commentId={id}
          hook={useMakeSchoolCommentLike}
          active={liked}
          value={likeCount}
          refetch={refetch}
        />
        <LikeButton
          type="dislike"
          commentId={id}
          hook={useMakeSchoolCommentDislike}
          active={disliked}
          value={dislikeCount}
          refetch={refetch}
        />
      </div>
    </div>
  </div>
)

SchoolComment.propTypes = {
  id: PropTypes.number.isRequired,
  reputationScore: PropTypes.number.isRequired,
  opportunitiesScore: PropTypes.number.isRequired,
  happinessScore: PropTypes.number.isRequired,
  clubsScore: PropTypes.number.isRequired,
  internetScore: PropTypes.number.isRequired,
  facilitiesScore: PropTypes.number.isRequired,
  locationScore: PropTypes.number.isRequired,
  foodScore: PropTypes.number.isRequired,
  safetyScore: PropTypes.number.isRequired,
  campusScore: PropTypes.number.isRequired,
  comment: PropTypes.string.isRequired,
  liked: PropTypes.bool.isRequired,
  disliked: PropTypes.bool.isRequired,
  likeCount: PropTypes.number.isRequired,
  dislikeCount: PropTypes.number.isRequired,
  createdDate: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
}

const SchoolDetailPage = () => (
  <SearchLayout>
    <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
      <SchoolInfo />
      {/* <Ads /> */}
      <SchoolComments />
      {/* <Ads /> */}
    </div>
  </SearchLayout>
)

export default SchoolDetailPage
