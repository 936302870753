import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import useComponentVisible from 'hooks/useComponentVisible'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import useLocalStorage from '@rehooks/local-storage'

import useUserMe from 'hooks/user/useUserMe'
import useSignOut from 'hooks/useSignOut'

import Button from 'common/components/buttons/button'

const Profile = () => {
  const signOut = useSignOut()
  const { data } = useUserMe()

  return (
    <div className="absolute z-50 w-64 bg-white border border-gray-200 rounded-lg shadow-lg right-6 top-20 lg:w-96">
      <div className="flex flex-col p-5 space-y-1 border-b border-gray-200">
        <h3 className="font-medium">
          {data && `${data.firstName} ${data.lastName}`}
        </h3>
      </div>
      <div className="flex flex-col p-5 space-y-1 font-medium">
        <Button
          color="custom"
          href="/app/profile"
          className="self-start font-medium text-secondary-700"
        >
          Hesap Bilgileri
        </Button>
        <Button
          color="custom"
          href="/app/my-ratings"
          className="self-start font-medium text-secondary-700"
        >
          Gönderilerim
        </Button>
        <Button
          color="custom"
          className="self-start font-medium text-secondary-700"
          onClick={signOut}
        >
          Çıkış Yap
        </Button>
      </div>
    </div>
  )
}

const MenuView = () => {
  const signOut = useSignOut()
  const [token] = useLocalStorage('token')

  const { isSuccess, error, data } = useUserMe({ enabled: !!token })
  const history = useHistory()

  const {
    ref: profileComponentRef,
    isComponentVisible: isProfileComponentVisible,
    setIsComponentVisible: setProfileComponentVisible,
  } = useComponentVisible(false)

  const goLogin = () => history.push('/login')
  const goRegister = () => history.push('/register')

  if (error) {
    signOut()
    return <Redirect to="/" />
  }

  return isSuccess ? (
    <div className="flex items-center sm:m-0">
      <div ref={profileComponentRef}>
        <button
          onClick={() => setProfileComponentVisible(!isProfileComponentVisible)}
          type="button"
          className="flex flex-row items-center space-x-2"
        >
          <FontAwesomeIcon icon={faUser} />
          <span className="hidden font-medium md:block">
            {data && `${data.firstName} ${data.lastName}`}
          </span>
        </button>
        {isProfileComponentVisible && <Profile />}
      </div>
    </div>
  ) : (
    <div className="flex flex-row items-center space-x-4">
      <Button onClick={goRegister} className="hidden font-bold md:flex">
        Kayıt Ol
      </Button>
      <Button
        variant="contained"
        onClick={goLogin}
        className="hidden font-bold rounded-full md:flex"
      >
        Giriş Yap
      </Button>
      <Button
        onClick={goLogin}
        className="flex text-xl text-gray-900 md:hidden"
      >
        <FontAwesomeIcon icon={faSignInAlt} />
      </Button>
    </div>
  )
}

export default MenuView
