import React, { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import useProfessors from 'hooks/professor/useProfessors'

import Select from 'common/components/inputs/select'

const ProfessorSearchBox = () => {
  const history = useHistory()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const {
    data: professors,
    isLoading: isProfessorsLoading,
    error: professorsError,
  } = useProfessors({
    query: search,
    size: 5,
    options: { enabled: isMenuOpen },
  })

  const [professor, setProfessor] = useState()

  const professorOptions =
    professors?.content.map((item) => ({
      label: `${item.firstName} ${item.lastName}`,
      value: item.id,
      departmentName: item.department.name,
      school: item.department.school.abbreviation,
    })) ?? []

  const onSearchProfessor = (val) => setSearch(val)

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchProfessor, 300),
    []
  )

  useEffect(
    () => () => debouncedChangeHandler.cancel(),
    [debouncedChangeHandler]
  )

  useEffect(() => {
    if (professor) history.push(`/professor/${professor.value}`)
  }, [history, professor])

  return (
    <Select
      placeholder="Öğretim Elemanı Ara"
      flex={3}
      options={professorOptions ?? []}
      value={professor}
      onChange={setProfessor}
      onInputChange={debouncedChangeHandler}
      setIsMenuOpen={setIsMenuOpen}
      isSearch
      isLoading={isProfessorsLoading}
      filterOption={() => true}
      menuIsOpen={search !== ''}
      error={professorsError?.message}
    />
  )
}

export default ProfessorSearchBox
