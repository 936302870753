import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import useLocalStorage from '@rehooks/local-storage'
import useUserMe from 'hooks/user/useUserMe'

import ProfilePage from './profile.page'
import MyRatingsPage from './myRatings.page'
import EditProfessorRatePage from './editProfessorRate.page'
import EditSchoolRatePage from './editSchoolRate.page'

const AppRoutes = () => {
  const [token] = useLocalStorage('token')

  const { isLoading, isSuccess, status } = useUserMe({ enabled: !!token })

  if (isLoading) return null

  if (status === 'error') {
    return <Redirect to="/error" />
  }

  if (token && isSuccess) {
    return (
      <Switch>
        <Route path="/app/profile" exact component={ProfilePage} />
        <Route path="/app/my-ratings" exact component={MyRatingsPage} />
        <Route
          path="/app/professor/:professorId/rate/:id"
          exact
          component={EditProfessorRatePage}
        />
        <Route
          path="/app/school/:schoolId/rate/:id"
          exact
          component={EditSchoolRatePage}
        />
        <Redirect to="/app/profile" />
      </Switch>
    )
  }

  return <Redirect to="/" />
}

export default AppRoutes
