import React from 'react'
import PropTypes from 'prop-types'

import SearchHeader from 'common/components/search.header'
import Footer from 'common/components/footer'
import clsx from 'clsx'

const SearchLayout = ({ children, className }) => (
  <div className={clsx('bg-bodyBackground', className)}>
    <SearchHeader />
    {children}
    <Footer />
  </div>
)

SearchLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

SearchLayout.defaultProps = {
  className: '',
}

export default SearchLayout
