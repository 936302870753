import React, { useState, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { useHistory } from 'react-router-dom'

import useSchools from 'hooks/school/useSchools'

import Select from 'common/components/inputs/select'

const SchoolSearchBox = () => {
  const history = useHistory()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [search, setSearch] = useState('')
  const {
    data: schools,
    isLoading: isSchoolsLoading,
    error: schoolsError,
  } = useSchools({
    query: search,
    options: { enabled: isMenuOpen },
  })

  const [school, setSchool] = useState()

  const schoolOptions =
    schools?.content.map((item) => ({
      label: item.name,
      value: item.id,
    })) ?? []

  const onSearchSchool = (val) => setSearch(val)

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearchSchool, 300),
    []
  )

  useEffect(
    () => () => debouncedChangeHandler.cancel(),
    [debouncedChangeHandler]
  )

  useEffect(() => {
    if (school) history.push(`/school/${school.value}`)
  }, [history, school])

  return (
    <Select
      placeholder="Üniversite Ara"
      flex={3}
      options={schoolOptions ?? []}
      value={school}
      onChange={setSchool}
      onInputChange={debouncedChangeHandler}
      setIsMenuOpen={setIsMenuOpen}
      isSearch
      isLoading={isSchoolsLoading}
      filterOption={() => true}
      menuIsOpen={search !== ''}
      error={schoolsError?.message}
    />
  )
}

export default SchoolSearchBox
