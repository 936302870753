import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useParams } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import useProfessor from 'hooks/professor/useProfessor'
import useCreateProfessorComment from 'hooks/professorComment/useCreateProfessorComment'

import SearchLayout from 'common/layouts/search.layout'
// import Ads from 'common/components/ads'
import InfoField from 'common/components/infoField'
import InfoItem from 'common/components/infoItem'
import Input from 'common/components/inputs/input'
import Radio from 'common/components/radio'
import Textarea from 'common/components/inputs/textarea'
import Slider from 'common/components/inputs/slider'
import SubmitButton from 'common/components/buttons/submitButton'
import Alert from 'common/components/alert'
import Spinner from 'common/components/spinner'

const RadioInput = ({ name, control }) => (
  <Controller
    control={control}
    name={name}
    defaultValue
    render={({ value, onChange }) => (
      <div className="flex flex-row space-x-6">
        <Radio
          id={name}
          label="Evet"
          checked={!!value}
          onChange={() => onChange(true)}
        />
        <Radio
          id={name}
          label="Hayır"
          checked={!value}
          onChange={() => onChange(false)}
        />
      </div>
    )}
  />
)

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
}

const COMMENT_MAX_LENGTH = 1500

const RateProfessorForm = () => {
  const { id } = useParams()
  const methods = useForm()
  const { mutate, isLoading, isSuccess, error } = useCreateProfessorComment(id)

  const [captchaToken, setCaptchaToken] = useState()

  const onSubmit = (data) => mutate({ data, captchaToken })

  useEffect(() => {
    if (isSuccess) {
      methods.reset()
    }
  }, [isSuccess])

  return (
    <div className="p-4 bg-white border border-gray-300 rounded">
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="flex flex-col space-y-6"
        >
          <InfoField>
            <InfoItem label="Ders Kodu / İsmi" labelClassName="w-64">
              <Input
                type="text"
                name="courseCode"
                ref={methods.register({
                  required: 'Lütfen ders kodu / ismi alanını doldurunuz!',
                })}
                error={methods.errors.code?.message}
              />
            </InfoItem>
            <InfoItem label="Kalite">
              <Controller
                control={methods.control}
                name="qualityScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen kalite alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Zorluk">
              <Controller
                control={methods.control}
                name="difficultyScore"
                defaultValue={1}
                rules={{
                  required: 'Lütfen zorluk alanını doldurunuz!',
                }}
                render={({ value, onChange }) => (
                  <Slider value={value} onChange={onChange} />
                )}
              />
            </InfoItem>
            <InfoItem label="Dersi tekrar alır mıydın?">
              <RadioInput
                name="takeAgain"
                label="Dersi tekrar alır mıydın?"
                control={methods.control}
              />
            </InfoItem>
            <InfoItem label="Katılım zorunlu muydu?">
              <RadioInput
                name="mandatoryAttendance"
                label="Katılım zorunlu muydu?"
                control={methods.control}
              />
            </InfoItem>
            <InfoItem label="Dersi kredi için mi aldın?">
              <RadioInput
                name="takenForCredits"
                label="Dersi kredi için mi aldın?"
                control={methods.control}
              />
            </InfoItem>
            <InfoItem label="Ders kitabı zorunlu mu?">
              <RadioInput
                name="textbookUsage"
                label="Ders kitabı zorunlu mu?"
                control={methods.control}
              />
            </InfoItem>
            <InfoItem label="Uzaktan eğitim">
              <RadioInput
                name="online"
                label="Uzaktan eğitim"
                control={methods.control}
              />
            </InfoItem>
            <InfoItem label="Görüşlerin" labelClassName="align-top" mobileFlex>
              <Textarea
                className="min-w-300"
                name="comment"
                rows="5"
                ref={methods.register({
                  required: 'Lütfen görüşlerin alanını doldurunuz!',
                  maxLength: {
                    value: COMMENT_MAX_LENGTH,
                    message: `Açıklamanız çok uzun. (Maksimum: ${COMMENT_MAX_LENGTH} karakter)`,
                  },
                })}
                charactersLeft={
                  COMMENT_MAX_LENGTH - methods.watch('comment')?.length
                }
                error={methods.errors.comment?.message}
              />
            </InfoItem>
            <InfoItem label=" " mobileFlex>
              <p className="pb-4">
                ‘Gönder’ butonuna tıklayarak{' '}
                <span className="font-bold underline">Gizlilik Politikası</span>
                ’nı,{' '}
                <span className="font-bold underline">Kullanım Koşulları</span>
                ’nı,{' '}
                <span className="font-bold underline">Çerez Politikası</span>
                ’nı ve{' '}
                <span className="font-bold underline">Aydınlatma Metni</span>
                ’ni kabul ediyorum. Gönderilen veri gönderim tarihinden itibaren
                ProfSözlük’e aittir.
              </p>
              <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
              <SubmitButton
                variant="contained"
                color="primary"
                size="large"
                status={isLoading ? 'disabled' : ''}
                className="mb-4"
              >
                {isLoading ? <Spinner color="white" /> : 'Gönder'}
              </SubmitButton>
              {error && (
                <Alert
                  type="error"
                  variant="contained"
                  message={error.message}
                />
              )}
              {isSuccess && (
                <>
                  <Alert
                    type="success"
                    variant="contained"
                    message="İşlem başarılı!"
                  />
                  <Redirect to={`/professor/${id}`} />
                </>
              )}
            </InfoItem>
          </InfoField>
        </form>
      </FormProvider>
    </div>
  )
}

const RateProfessorPage = () => {
  const { id } = useParams()
  const { data, isLoading, error } = useProfessor(id)

  return isLoading ? (
    <Spinner />
  ) : error ? (
    <Alert type="error" variant="contained" message={error.message} />
  ) : (
    <SearchLayout>
      <div className="container flex flex-col px-4 pt-12 pb-20 mx-auto space-y-8">
        <h1 className="text-4xl">
          <span className="font-bold">
            <a href={`/professor/${data.id}`}>
              {data.firstName} {data.lastName}
            </a>
          </span>{' '}
          için yorum yap!
        </h1>
        <RateProfessorForm />
        {/* <Ads /> */}
      </div>
    </SearchLayout>
  )
}

export default RateProfessorPage
